// By convention, all experiments MUST have a "control" variant.
export enum Experiment {
  ANON_AI_ASSISTANT_RETRY = 'anon_ai_assistant_retry',
  ANON_MODULE_BASED_GATING = 'anon_module_based_gating',
  CHECKOUT_ITERATION = 'checkout_iteration',
  CHECKOUT_STYLING = 'checkout_styling',
  CHECKOUT_SUMMARY_ITERATION = 'checkout_summary_iteration',
  CODE_CHALLENGE_V2 = 'code_challenge_v2',
  DIGIOH_CHECKOUT_EXIT_INTENT = 'digioh_checkout_exit_intent',
  DIGIOH_TEST = 'digioh_test',
  FREE_JOB_REPORT = 'free_job_report',
  INDIA_MOBILE_NUMBER_FLOW = 'india_mobile_number_flow',
  INTERSTITIAL_AB = 'interstitial_ab',
  MODULAR_CHECKOUT_V2 = 'modular_checkout_v2',
  MONEYBACK_GUARANTEE = 'moneyback_guarantee_v2',
  MY_LEARNING_EMPTY_STATES = 'my_learning_empty_states',
  ONE_TIME_PAYMENT = 'onetime_payment',
  STUDENT_CHECKOUT = 'student_checkout',
  RATE_LIMIT_AI = 'rate_limit_ai_v2',
  RATE_LIMIT_PROJECTS = 'rate_limit_projects',
  RATE_LIMIT_QUIZZES = 'rate_limit_quizzes',
  SHOW_SECONDARY_NAV = 'show_secondary_nav',
  UNIFIED_RECOMMENDATION_FLOW = 'unified_recommendation_flow',
  PRODUCT_FEATURE_MARKETING_PRICING = 'product_feature_marketing_pricing',
  LE_SYLLABUS_BROWSER = 'le_syllabus_browser',
  EXPANDED_PCF = 'expanded_pcf',
}

export enum BasicExperimentVariant {
  CONTROL = 'control',
  VARIANT = 'variant',
}

export enum CheckoutIterationVariant {
  CONTROL = 'control',
  VARIANT_1 = 'variant_1',
  VARIANT_2 = 'variant_2',
}

export enum IndiaMobileNumberExperimentVariant {
  CONTROL = 'control',
  VARIANT_A = 'variant_a',
  VARIANT_B = 'variant_b',
}

export enum ThreeBucketExperimentVariant {
  CONTROL = 'control',
  VARIANT_A = 'variant_a',
  VARIANT_B = 'variant_b',
}

type AllExperiments = {
  [Experiment.ANON_AI_ASSISTANT_RETRY]: BasicExperimentVariant;
  [Experiment.ANON_MODULE_BASED_GATING]: BasicExperimentVariant;
  [Experiment.CHECKOUT_ITERATION]: CheckoutIterationVariant;
  [Experiment.CHECKOUT_STYLING]: BasicExperimentVariant;
  [Experiment.CHECKOUT_SUMMARY_ITERATION]: CheckoutIterationVariant;
  [Experiment.CODE_CHALLENGE_V2]: BasicExperimentVariant;
  [Experiment.DIGIOH_CHECKOUT_EXIT_INTENT]: BasicExperimentVariant;
  [Experiment.DIGIOH_TEST]: BasicExperimentVariant;
  [Experiment.FREE_JOB_REPORT]: BasicExperimentVariant;
  [Experiment.INDIA_MOBILE_NUMBER_FLOW]: IndiaMobileNumberExperimentVariant;
  [Experiment.INTERSTITIAL_AB]: ThreeBucketExperimentVariant;
  [Experiment.MODULAR_CHECKOUT_V2]: BasicExperimentVariant;
  [Experiment.MONEYBACK_GUARANTEE]: BasicExperimentVariant;
  [Experiment.MY_LEARNING_EMPTY_STATES]: ThreeBucketExperimentVariant;
  [Experiment.ONE_TIME_PAYMENT]: BasicExperimentVariant;
  [Experiment.STUDENT_CHECKOUT]: BasicExperimentVariant;
  [Experiment.RATE_LIMIT_AI]: BasicExperimentVariant;
  [Experiment.RATE_LIMIT_PROJECTS]: BasicExperimentVariant;
  [Experiment.RATE_LIMIT_QUIZZES]: BasicExperimentVariant;
  [Experiment.SHOW_SECONDARY_NAV]: BasicExperimentVariant;
  [Experiment.UNIFIED_RECOMMENDATION_FLOW]: BasicExperimentVariant;
  [Experiment.PRODUCT_FEATURE_MARKETING_PRICING]: BasicExperimentVariant;
  [Experiment.LE_SYLLABUS_BROWSER]: BasicExperimentVariant;
  [Experiment.EXPANDED_PCF]: BasicExperimentVariant;
};

export type Experiments = Partial<AllExperiments>;
export type ExperimentVariant<T extends Experiment> = AllExperiments[T];
