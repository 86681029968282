import { GlobalFooterProps } from '@mono/brand';
import { FeatureFlag } from '@mono/data/feature-flags';
import { useOptimizelyFeature } from '@mono/data/optimizely';
import { useUser } from '@mono/data/user';

import { trackUserClick } from '~/libs/tracking';
import { useEnterpriseUser } from '~/libs/useEnterpriseUser';

export const useFooterProps = (
  pwaMode: boolean,
  hideFooter: boolean
): GlobalFooterProps | undefined => {
  const user = useUser();
  const enterpriseUser = useEnterpriseUser(user);
  const referralRockEnabled = useOptimizelyFeature(
    FeatureFlag.REFERRAL_ROCK_V1
  );

  if (hideFooter || enterpriseUser) return undefined;

  return {
    hidePricing: pwaMode,
    onClick: ({ target }) => {
      trackUserClick({ context: 'global_footer', target });
    },
    userGeo: user?.location.geo_country,
    referralRockUrls:
      (referralRockEnabled && {
        stats: process.env.NEXT_PUBLIC_REFERRALROCK_STATS_URL ?? '',
        tos: process.env.NEXT_PUBLIC_REFERRALROCK_TOS_URL ?? '',
      }) ||
      undefined,
  };
};
