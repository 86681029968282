import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  File: { input: any; output: any; }
  ISO8601DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  _Any: { input: any; output: any; }
};

export enum BusinessCohortSortEnum {
  Name = 'name',
  Seats = 'seats',
  StartDate = 'start_date'
}

export enum BusinessMemberEnum {
  Invitation = 'invitation',
  Seat = 'seat'
}

export enum BusinessPlanSortEnum {
  CustomName = 'custom_name',
  PlanStartDate = 'plan_start_date'
}

export enum BusinessPlanTypeEnum {
  Business = 'BUSINESS',
  BusinessPro = 'BUSINESS_PRO',
  BusinessTrial = 'BUSINESS_TRIAL',
  Campus = 'CAMPUS',
  CampusTrial = 'CAMPUS_TRIAL',
  Channel = 'CHANNEL',
  Custom = 'CUSTOM',
  Education = 'EDUCATION',
  Enterprise = 'ENTERPRISE',
  EnterpriseTrial = 'ENTERPRISE_TRIAL',
  Nonprofit = 'NONPROFIT',
  Skillsoft = 'SKILLSOFT',
  Talent = 'TALENT'
}

export enum BusinessSeatSortEnum {
  CreatedAt = 'created_at',
  Email = 'email',
  Groups = 'groups',
  Joined = 'joined',
  Name = 'name',
  Role = 'role',
  Status = 'status'
}

export enum BusinessTierEnum {
  /** Enterprise */
  Enterprise = 'ENTERPRISE',
  /** Teams */
  Teams = 'TEAMS',
  /** Teams+ */
  TeamsPlus = 'TEAMS_PLUS'
}

export enum BusinessUserRole {
  AccountManager = 'account_manager',
  AccountManagerFree = 'account_manager_free',
  Team = 'team'
}

export enum CatalogCategoryType {
  Language = 'language',
  Subject = 'subject'
}

export enum ContentContainerEnum {
  ExternalCourse = 'external_course',
  Journey = 'journey',
  Module = 'module',
  Path = 'path',
  Track = 'track'
}

export enum CourseDifficulty {
  Advanced = 'Advanced',
  Beginner = 'Beginner',
  Intermediate = 'Intermediate'
}

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Inr = 'INR',
  Nok = 'NOK',
  Pln = 'PLN',
  Sek = 'SEK',
  Usd = 'USD'
}

export type EnrollmentInput = {
  /** Optional legacy input for sending extra data to Segment. */
  contentId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Optional legacy input for sending extra data to Segment. */
  moduleId?: InputMaybe<Scalars['String']['input']>;
  /** Optional legacy input for sending extra data to Segment. */
  pathName?: InputMaybe<Scalars['String']['input']>;
  type: EnrollmentTypeEnum;
};

export enum EnrollmentStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
  None = 'none'
}

export enum EnrollmentTypeEnum {
  EnterpriseModule = 'enterprise_module',
  ExternalCourse = 'external_course',
  Journey = 'journey',
  Path = 'path',
  Track = 'track'
}

/** The name of the Codecademy feature that user was interacting with */
export type HubspotContactInput = {
  ccFeature?: InputMaybe<Scalars['String']['input']>;
  ccSource?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  jobtitle?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  numberOfSeats?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum NameVisibilityEnum {
  Private = 'private',
  ProfileVisibility = 'profile_visibility'
}

export enum PathGoalEnum {
  Career = 'career',
  Skill = 'skill'
}

export enum ProfileVisibilityEnum {
  Authenticated = 'authenticated',
  Public = 'public',
  Restricted = 'restricted'
}

export type ResetProgressInput = {
  id: Scalars['ID']['input'];
  type: ContentContainerEnum;
};

export enum ResourceType {
  Article = 'Article',
  Blog = 'Blog',
  Cheatsheet = 'Cheatsheet',
  Docs = 'Docs',
  External = 'External',
  Forum = 'Forum',
  Glossary = 'Glossary',
  Project = 'Project',
  Quiz = 'Quiz',
  Resource = 'Resource',
  Video = 'Video'
}

export enum SortContentOrderEnum {
  Popularity = 'popularity'
}

export enum SortOrderParam {
  Asc = 'asc',
  Desc = 'desc'
}

export type UserInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  handles?: InputMaybe<UserSitesInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameVisibility?: InputMaybe<Scalars['String']['input']>;
  oneTimeTriggers?: InputMaybe<Array<Scalars['String']['input']>>;
  profileImage?: InputMaybe<Scalars['File']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UserSitesInput = {
  github?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export enum UserType {
  Anonymous = 'anonymous',
  Business = 'business',
  Education = 'education',
  Free = 'free',
  Pro = 'pro',
  ProOther = 'pro_other',
  ProStudent = 'pro_student',
  ProStudentTrial = 'pro_student_trial',
  Trial = 'trial'
}

export type StaffUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffUsersQuery = { staffUsers: Array<{ name: string, profileImageUrl: string, staffDepartment?: string | null, staffTitle?: string | null, username: string }> };

export type AdminDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDetailsQuery = { me: { id: string, role?: string | null, type: UserType, anonymous: boolean } };

export type BootcampRecordingQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type BootcampRecordingQuery = { me: { id: string, anonymous: boolean, username: string, email: string, enrolledBootcamps: Array<{ slug: string }> }, bootcamp?: { id: string, name: string, slug: string, startAt: string, endAt: string, sessions: Array<{ startAt: string, duration: number, vimeoId: string }> } | null };

export type BootcampSessionQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type BootcampSessionQuery = { me: { id: string, anonymous: boolean, username: string, email: string, enrolledBootcamps: Array<{ slug: string }> }, bootcamp?: { id: string, name: string, slug: string, startAt: string, endAt: string, sessions: Array<{ startAt: string, duration: number, meetingId: string }> } | null };

export type CumulativeAssessmentScoreStateDataFragment = { containerId: string, containerType: string, correct: number, total: number };

export type SectionAssessmentAttemptDataFragment = { id: string, userId: string, createdAt: string, updatedAt: string, containerId: string, containerType: string, containerSection?: string | null, averageScore?: number | null, totalCorrect?: number | null, totalCount?: number | null, scoreState: Array<{ containerId: string, containerType: string, correct: number, total: number }> };

export type CareerJourneyProgressQueryVariables = Exact<{
  containerId: Scalars['String']['input'];
  containerType: Scalars['String']['input'];
  includeProgressItems?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CareerJourneyProgressQuery = { containerProgress: { id?: string | null, completed?: boolean | null, itemsTotal?: number | null, itemsCompleted?: number | null, percentComplete?: number | null, started?: boolean | null, childCollections?: Array<{ id?: string | null, completed?: boolean | null, itemsTotal?: number | null, itemsCompleted?: number | null, percentComplete?: number | null, started?: boolean | null }> | null, contentItemProgresses?: Array<{ contentItemId: string, completed: boolean, started: boolean }> | null } };

export type CareerJourneyResumeLocationQueryVariables = Exact<{
  containerId: Scalars['String']['input'];
  containerType: Scalars['String']['input'];
}>;


export type CareerJourneyResumeLocationQuery = { resumeLocation: { error?: string | null, resume?: { journeyId?: string | null, journeySlug?: string | null, pathId?: string | null, pathSlug?: string | null, trackId?: string | null, courseSlug?: string | null, moduleId?: string | null, moduleSlug?: string | null, contentItemId?: string | null } | null } };

export type JobActionProgressQueryVariables = Exact<{
  jobActionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type JobActionProgressQuery = { jobActionProgress: Array<{ jobActionId: string, isComplete: boolean }> };

export type CertificatesDataFragment = { id: string, containerType: ContentContainerEnum };

export type CareerJourneyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CareerJourneyUserQuery = { me: { id: string, anonymous: boolean, featureFlags: Array<string>, proGold: boolean, proSilver: boolean, oneTimeTriggers: Array<string>, username: string, businessUser: boolean, certificates: Array<{ id: string, containerType: ContentContainerEnum }> } };

export type CareerJourneyTrackUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CareerJourneyTrackUserQuery = { me: { id: string, anonymous: boolean, proGold: boolean, proSilver: boolean } };

export type CareerJourneyUserEnrollmentQueryVariables = Exact<{ [key: string]: never; }>;


export type CareerJourneyUserEnrollmentQuery = { me: { id: string, enrolledJourneys: Array<{ createdAt: string, id: string }> } };

export type CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables = Exact<{
  containerId: Scalars['String']['input'];
}>;


export type CareerJourneyCumulativeAssessmentAttemptSummaryQuery = { cumulativeAssessmentAttemptSummary: { userId?: string | null, containerId?: string | null, containerType?: string | null, totalAssessmentsPassed?: number | null, errorMessage?: string | null, cumulativeAssessmentAttempts?: Array<{ id: string, userId: string, createdAt: string, updatedAt: string, containerId: string, containerType: string, containerSection?: string | null, averageScore?: number | null, totalCorrect?: number | null, totalCount?: number | null, scoreState: Array<{ containerId: string, containerType: string, correct: number, total: number }> }> | null } };

export type EnrollInJourneyMutationVariables = Exact<{
  container: EnrollmentInput;
}>;


export type EnrollInJourneyMutation = { enrollContainer: { enrollmentId?: string | null, enrollmentType?: EnrollmentTypeEnum | null, error?: string | null } };

export type MarkJobActionMutationVariables = Exact<{
  jobActionId: Scalars['String']['input'];
  isComplete: Scalars['Boolean']['input'];
}>;


export type MarkJobActionMutation = { markJobAction: { __typename: 'MarkJobActionMutationPayload', error?: string | null, jobActionProgress?: { __typename: 'JobActionProgress', jobActionId: string, isComplete: boolean } | null } };

export type ResetContainerProgressMutationVariables = Exact<{
  containers: Array<ResetProgressInput> | ResetProgressInput;
}>;


export type ResetContainerProgressMutation = { resetContainerProgress: { containersReset?: Array<string> | null } };

export type TransferCodeChallengeMutationVariables = Exact<{
  assessmentSlug: Scalars['String']['input'];
  codeChallengeAttemptId: Scalars['String']['input'];
}>;


export type TransferCodeChallengeMutation = { transferCodeChallenge: { error?: string | null, attempt?: { id: string, userId: string, createdAt: string, updatedAt: string, containerId: string, containerType: string, containerSection?: string | null, averageScore?: number | null, totalCorrect?: number | null, totalCount?: number | null, scoreState: Array<{ containerId: string, containerType: string, correct: number, total: number }> } | null } };

export type VerifiedStudentQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifiedStudentQuery = { me: { id: string, verifiedStudent: boolean } };

export type ChallengesUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ChallengesUserQuery = { me: { id: string, proGold: boolean, proSilver: boolean } };

export type CheatsheetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CheatsheetCategoriesQuery = { catalog: { filters: Array<{ slug: CatalogCategoryType, title: string, items: Array<{ slug: string, title: string }> }> } };

export type CheatsheetCategoriesSitemapQueryVariables = Exact<{ [key: string]: never; }>;


export type CheatsheetCategoriesSitemapQuery = { catalog: { filters: Array<{ slug: CatalogCategoryType, items: Array<{ slug: string }> }> } };

export type CheckoutQueryVariables = Exact<{
  noTrial?: InputMaybe<Scalars['Boolean']['input']>;
  referredByCode?: InputMaybe<Scalars['Boolean']['input']>;
  isPwa?: InputMaybe<Scalars['Boolean']['input']>;
  studentPlans?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CheckoutQuery = { me: { id: string, anonymous: boolean, pro: boolean, verified: boolean, email: string, unconfirmedEmail?: string | null, verifiedStudent: boolean, subscriptionPaused: boolean, mbgEligible: boolean, studentVerification?: { verificationId?: string | null } | null }, pricing: { heading: string, description: string, currency: Currency, subdescription: string, backgroundImage: { image: string }, logos: Array<{ image: string, alt?: string | null }>, products: Array<{ isTrialPlan?: boolean | null, price: string, planId?: string | null, displayName: string, planType: string, savings?: string | null, includedTaxRate?: string | null }> } };

export type BootcampPageQueryVariables = Exact<{
  purchaseType: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;


export type BootcampPageQuery = { me: { id: string, anonymous: boolean, pro: boolean, enrolledBootcamps: Array<{ slug: string }> }, bootcamp?: { id: string, name: string, slug: string, startAt: string, registrationStartAt: string, registrationEndAt: string, endAt: string, enrollmentLimit: number, sessions: Array<{ startAt: string, duration: number }> } | null, pay?: { id?: string | null, slug?: string | null, price?: any | null, meta?: any | null, error?: string | null } | null };

export type ClpUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ClpUserQuery = { me: { id: string, pro: boolean, proSilver: boolean, proGold: boolean, anonymous: boolean, experiments: any, freeProjectsTaken?: Array<string> | null, freeQuizzesTaken?: Array<string> | null, enrollments: Array<{ id: string } | {}> } };

export type ExtCourseUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ExtCourseUserQuery = { me: { id: string, pro: boolean, anonymous: boolean } };

export type UserWithEnrollmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithEnrollmentsQuery = { me: { id: string, pro: boolean, anonymous: boolean, proGold: boolean, proSilver: boolean, businessUser: boolean, skillsoftUser: boolean, mbgEligible: boolean, enrollments: Array<{ id: string, type?: ContentContainerEnum | null } | { id: string, type?: ContentContainerEnum | null } | {}> } };

export type JobResumeQueryVariables = Exact<{ [key: string]: never; }>;


export type JobResumeQuery = { me: { id: string, proGold: boolean, proSilver: boolean, anonymous: boolean, oneTimeTriggers: Array<string>, businessUser: boolean, jobResume?: { id: string, userInput: string } | null } };

export type JobAnalysisDataFragment = { jobTitle: string, company: string, score: string, areasToImprove: Array<string>, strengths: Array<string>, reasonForScore: string, skills?: Array<string> | null, skillsToLearn?: Array<string> | null, createdAt: string, id: string, jobUrl: string };

export type JobAnalysisQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type JobAnalysisQuery = { jobAnalysis?: { jobTitle: string, company: string, score: string, areasToImprove: Array<string>, strengths: Array<string>, reasonForScore: string, skills?: Array<string> | null, skillsToLearn?: Array<string> | null, createdAt: string, id: string, jobUrl: string } | null, me: { id: string, proGold: boolean, jobResume?: { id: string } | null } };

type EnrollmentReadinessData_Course_Fragment = { id: string, title: string };

type EnrollmentReadinessData_Journey_Fragment = { id: string, title: string };

type EnrollmentReadinessData_Path_Fragment = { id: string, title: string };

export type EnrollmentReadinessDataFragment = EnrollmentReadinessData_Course_Fragment | EnrollmentReadinessData_Journey_Fragment | EnrollmentReadinessData_Path_Fragment;

export type EnrollmentJourneyReadinessDataFragment = { type?: ContentContainerEnum | null, id: string, title: string };

export type EnrollmentPathReadinessDataFragment = { type?: ContentContainerEnum | null, id: string, title: string };

export type EnrollmentCourseReadinessDataFragment = { type?: ContentContainerEnum | null, id: string, title: string };

type EnrollmentReadiness_Course_Fragment = { type?: ContentContainerEnum | null, id: string, title: string };

type EnrollmentReadiness_Journey_Fragment = { type?: ContentContainerEnum | null, id: string, title: string };

type EnrollmentReadiness_Path_Fragment = { type?: ContentContainerEnum | null, id: string, title: string };

export type EnrollmentReadinessFragment = EnrollmentReadiness_Course_Fragment | EnrollmentReadiness_Journey_Fragment | EnrollmentReadiness_Path_Fragment;

export type JobResumeDataFragment = { userInput: string };

export type CareerReadinessQueryVariables = Exact<{ [key: string]: never; }>;


export type CareerReadinessQuery = { me: { id: string, proGold: boolean, proSilver: boolean, anonymous: boolean, businessUser: boolean, certificates: Array<{ id: string, slug: string, containerType: ContentContainerEnum, title: string, certifiedAt: string, lastCompletedAt: string, goal?: PathGoalEnum | null, completionId: string, completedPaths?: Array<{ title: string }> | null }>, enrollments: Array<{ type?: ContentContainerEnum | null, id: string, title: string } | { type?: ContentContainerEnum | null, id: string, title: string } | { type?: ContentContainerEnum | null, id: string, title: string } | {}>, jobAnalyses?: Array<{ jobTitle: string, company: string, score: string, areasToImprove: Array<string>, strengths: Array<string>, reasonForScore: string, skills?: Array<string> | null, skillsToLearn?: Array<string> | null, createdAt: string, id: string, jobUrl: string }> | null, jobResume?: { userInput: string } | null } };

export type UserAnonymousQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAnonymousQuery = { me: { id: string, proGold: boolean, proSilver: boolean, anonymous: boolean } };

export type PayServerQueryVariables = Exact<{
  purchaseType: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;


export type PayServerQuery = { me: { id: string, anonymous: boolean, pro: boolean, verified: boolean, email: string, unconfirmedEmail?: string | null }, pay?: { id?: string | null, slug?: string | null, heading?: string | null, price?: any | null, meta?: any | null, error?: string | null } | null };

export type BrandedBannerUserQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandedBannerUserQuery = { me: { id: string, role?: string | null, pro: boolean, type: UserType } };

export type PastDueModalUserQueryVariables = Exact<{ [key: string]: never; }>;


export type PastDueModalUserQuery = { me: { id: string, pastDue: boolean, pastDueErrCode?: string | null, latestPaymentMethod?: string | null, provider?: string | null, currentRenewalDate?: string | null, paymentLink?: string | null, cancellationLink?: string | null, inPreDunning: boolean } };

export type FeatureFlagsAndExperimentsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagsAndExperimentsQuery = { me: { id: string, username: string, anonymous: boolean, featureFlags: Array<string>, experiments: any } };

export type ProductDataFragment = { isTrialPlan?: boolean | null, price: string, planId?: string | null, displayName: string, planType: string, savings?: string | null, includedTaxRate?: string | null };

export type PricingQueryVariables = Exact<{
  noTrial?: InputMaybe<Scalars['Boolean']['input']>;
  referredByCode?: InputMaybe<Scalars['Boolean']['input']>;
  isPwa?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PricingQuery = { me: { id: string, anonymous: boolean, pro: boolean, proSilver: boolean, verified: boolean, email: string, unconfirmedEmail?: string | null, mbgEligible: boolean }, pricing: { description: string, currency: Currency, subdescription: string, backgroundImage: { image: string }, logos: Array<{ image: string, alt?: string | null }>, products: Array<{ isTrialPlan?: boolean | null, price: string, planId?: string | null, displayName: string, planType: string, savings?: string | null, includedTaxRate?: string | null }> } };

export type ProfileEditPageQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type ProfileEditPageQuery = { me: { id: string, businessUser: boolean }, profile: { verified: boolean, visibility: ProfileVisibilityEnum, id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, bio?: string | null, createdAt: string, handle: string, lastCoded?: string | null, location?: string | null, name?: string | null, nameVisibility: NameVisibilityEnum, role?: string | null, website?: string | null, type: 'User', handles: { github?: string | null, linkedIn?: string | null, twitter?: string | null }, sites: { github?: string | null, linkedIn?: string | null, twitter?: string | null } } | {} };

export type EditUserMutationVariables = Exact<{
  userData: UserInput;
  id: Scalars['ID']['input'];
}>;


export type EditUserMutation = { editUser: { errors?: Array<{ message: string }> | null, user: { bio?: string | null, id: string, location?: string | null, name?: string | null, nameVisibility: NameVisibilityEnum, profileImageUrl: string, username: string, verified: boolean, visibility: ProfileVisibilityEnum, website?: string | null, handles: { github?: string | null, linkedIn?: string | null, twitter?: string | null } } } };

export type AchievementDataFragment = { slug: string, name?: string | null, badgeIcon?: string | null, createdAt: string };

export type CertificateDataFragment = { id: string, slug: string, containerType: ContentContainerEnum, title: string, certifiedAt: string, lastCompletedAt: string, goal?: PathGoalEnum | null, completionId: string, completedPaths?: Array<{ title: string }> | null };

type EnrollmentBaseData_Course_Fragment = { id: string, slug: string, title: string, pro: boolean };

type EnrollmentBaseData_Journey_Fragment = { id: string, slug: string, title: string, pro: boolean };

type EnrollmentBaseData_Path_Fragment = { id: string, slug: string, title: string, pro: boolean };

export type EnrollmentBaseDataFragment = EnrollmentBaseData_Course_Fragment | EnrollmentBaseData_Journey_Fragment | EnrollmentBaseData_Path_Fragment;

export type EnrollmentJourneyDataFragment = { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean };

export type EnrollmentPathDataFragment = { type?: ContentContainerEnum | null, goal: PathGoalEnum, id: string, slug: string, title: string, pro: boolean };

export type EnrollmentCourseDataFragment = { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean };

export type ExternalCourseDataFragment = { id: string, slug: string, title: string, pro: boolean, type?: ContentContainerEnum | null };

type EnrollmentExternalCourseData_CatalogCategory_Fragment = {};

type EnrollmentExternalCourseData_Course_Fragment = {};

type EnrollmentExternalCourseData_ExternalCourse_Fragment = { id: string, slug: string, title: string, pro: boolean, type?: ContentContainerEnum | null };

type EnrollmentExternalCourseData_Journey_Fragment = {};

type EnrollmentExternalCourseData_Path_Fragment = {};

export type EnrollmentExternalCourseDataFragment = EnrollmentExternalCourseData_CatalogCategory_Fragment | EnrollmentExternalCourseData_Course_Fragment | EnrollmentExternalCourseData_ExternalCourse_Fragment | EnrollmentExternalCourseData_Journey_Fragment | EnrollmentExternalCourseData_Path_Fragment;

type EnrollmentData_Course_Fragment = { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean };

type EnrollmentData_Journey_Fragment = { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean };

type EnrollmentData_Path_Fragment = { type?: ContentContainerEnum | null, goal: PathGoalEnum, id: string, slug: string, title: string, pro: boolean };

export type EnrollmentDataFragment = EnrollmentData_Course_Fragment | EnrollmentData_Journey_Fragment | EnrollmentData_Path_Fragment;

export type StreakDataFragment = { currentWeeklyStreak: number, longestWeeklyStreak: number };

export type UserBioDataFragment = { bio?: string | null, createdAt: string, handle: string, lastCoded?: string | null, location?: string | null, name?: string | null, nameVisibility: NameVisibilityEnum, role?: string | null, website?: string | null, sites: { github?: string | null, linkedIn?: string | null, twitter?: string | null } };

type BaseProfileData_PrivateUser_Fragment = { id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, type: 'PrivateUser' };

type BaseProfileData_User_Fragment = { id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, type: 'User' };

export type BaseProfileDataFragment = BaseProfileData_PrivateUser_Fragment | BaseProfileData_User_Fragment;

export type PrivateProfileDataFragment = { id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, type: 'PrivateUser' };

export type PublicProfileDataFragment = { discourseUsername?: string | null, id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, bio?: string | null, createdAt: string, handle: string, lastCoded?: string | null, location?: string | null, name?: string | null, nameVisibility: NameVisibilityEnum, role?: string | null, website?: string | null, type: 'User', achievements: Array<{ slug: string, name?: string | null, badgeIcon?: string | null, createdAt: string }>, certificates: Array<{ id: string, slug: string, containerType: ContentContainerEnum, title: string, certifiedAt: string, lastCompletedAt: string, goal?: PathGoalEnum | null, completionId: string, completedPaths?: Array<{ title: string }> | null }>, enrollments: Array<{ type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean } | { id: string, slug: string, title: string, pro: boolean, type?: ContentContainerEnum | null } | { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean } | { type?: ContentContainerEnum | null, goal: PathGoalEnum, id: string, slug: string, title: string, pro: boolean } | {}>, enrolledJourneys: Array<{ id: string }>, streaks: { currentWeeklyStreak: number, longestWeeklyStreak: number }, sites: { github?: string | null, linkedIn?: string | null, twitter?: string | null } };

export type NotFoundProfileDataFragment = { message: string, unauthorized?: boolean | null, type: 'UserNotFound' };

export type ProfilePageQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type ProfilePageQuery = { me: { id: string, businessUser: boolean, proGold: boolean }, profile: { id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, type: 'PrivateUser' } | { discourseUsername?: string | null, id: string, pro: boolean, proGold: boolean, profileImageUrl: string, username: string, bio?: string | null, createdAt: string, handle: string, lastCoded?: string | null, location?: string | null, name?: string | null, nameVisibility: NameVisibilityEnum, role?: string | null, website?: string | null, type: 'User', achievements: Array<{ slug: string, name?: string | null, badgeIcon?: string | null, createdAt: string }>, certificates: Array<{ id: string, slug: string, containerType: ContentContainerEnum, title: string, certifiedAt: string, lastCompletedAt: string, goal?: PathGoalEnum | null, completionId: string, completedPaths?: Array<{ title: string }> | null }>, enrollments: Array<{ type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean } | { id: string, slug: string, title: string, pro: boolean, type?: ContentContainerEnum | null } | { type?: ContentContainerEnum | null, id: string, slug: string, title: string, pro: boolean } | { type?: ContentContainerEnum | null, goal: PathGoalEnum, id: string, slug: string, title: string, pro: boolean } | {}>, enrolledJourneys: Array<{ id: string }>, streaks: { currentWeeklyStreak: number, longestWeeklyStreak: number }, sites: { github?: string | null, linkedIn?: string | null, twitter?: string | null } } | { message: string, unauthorized?: boolean | null, type: 'UserNotFound' } };

export type ProfileMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileMeQuery = { me: { id: string, username: string, anonymous: boolean } };

export type CreateDiscourseUserMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDiscourseUserMutation = { createDiscourseUser: { discourseUsername?: string | null } };

export type ProgressQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  containerType: Scalars['String']['input'];
  containerId: Scalars['String']['input'];
}>;


export type ProgressQuery = { containerProgress: { percentComplete?: number | null } };

export type UserIsProQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIsProQuery = { me: { id: string, pro: boolean } };

export type ProjectProgressDataFragment = { contentItemId: string, started: boolean, completed: boolean };

export type PracticeProjectsProgressQueryVariables = Exact<{
  projectIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type PracticeProjectsProgressQuery = { practiceProjectsProgress: Array<{ contentItemId: string, started: boolean, completed: boolean }> };

export type EditUserOneTimeTriggersMutationVariables = Exact<{
  triggerName: Scalars['String']['input'];
  markTrigger: Scalars['Boolean']['input'];
}>;


export type EditUserOneTimeTriggersMutation = { editUserOneTimeTriggers: { oneTimeTriggers: Array<string> } };

export const CumulativeAssessmentScoreStateDataFragmentDoc = gql`
    fragment cumulativeAssessmentScoreStateData on CumulativeAssessmentScoreState {
  containerId
  containerType
  correct
  total
}
    `;
export const SectionAssessmentAttemptDataFragmentDoc = gql`
    fragment sectionAssessmentAttemptData on CumulativeAssessmentAttempt {
  id
  userId
  createdAt
  updatedAt
  containerId
  containerType
  containerSection
  averageScore
  totalCorrect
  totalCount
  scoreState {
    ...cumulativeAssessmentScoreStateData
  }
}
    ${CumulativeAssessmentScoreStateDataFragmentDoc}`;
export const CertificatesDataFragmentDoc = gql`
    fragment certificatesData on Certificate {
  id
  containerType
}
    `;
export const JobAnalysisDataFragmentDoc = gql`
    fragment jobAnalysisData on JobAnalysis {
  jobTitle
  company
  score
  areasToImprove
  strengths
  reasonForScore
  skills
  skillsToLearn
  createdAt
  id
  jobUrl
}
    `;
export const EnrollmentReadinessDataFragmentDoc = gql`
    fragment enrollmentReadinessData on CatalogContentInterface {
  id
  title
}
    `;
export const EnrollmentJourneyReadinessDataFragmentDoc = gql`
    fragment enrollmentJourneyReadinessData on Journey {
  ...enrollmentReadinessData
  type
}
    ${EnrollmentReadinessDataFragmentDoc}`;
export const EnrollmentPathReadinessDataFragmentDoc = gql`
    fragment enrollmentPathReadinessData on Path {
  ...enrollmentReadinessData
  type
}
    ${EnrollmentReadinessDataFragmentDoc}`;
export const EnrollmentCourseReadinessDataFragmentDoc = gql`
    fragment enrollmentCourseReadinessData on Course {
  ...enrollmentReadinessData
  type
}
    ${EnrollmentReadinessDataFragmentDoc}`;
export const EnrollmentReadinessFragmentDoc = gql`
    fragment enrollmentReadiness on CatalogContentInterface {
  ...enrollmentJourneyReadinessData
  ...enrollmentPathReadinessData
  ...enrollmentCourseReadinessData
}
    ${EnrollmentJourneyReadinessDataFragmentDoc}
${EnrollmentPathReadinessDataFragmentDoc}
${EnrollmentCourseReadinessDataFragmentDoc}`;
export const JobResumeDataFragmentDoc = gql`
    fragment jobResumeData on JobResume {
  userInput
}
    `;
export const ProductDataFragmentDoc = gql`
    fragment productData on ProductDetails {
  isTrialPlan
  price
  planId
  displayName
  planType
  savings
  includedTaxRate
}
    `;
export const BaseProfileDataFragmentDoc = gql`
    fragment baseProfileData on BaseUserInterface {
  type: __typename
  id
  pro
  proGold
  profileImageUrl
  username
}
    `;
export const PrivateProfileDataFragmentDoc = gql`
    fragment privateProfileData on PrivateUser {
  ...baseProfileData
}
    ${BaseProfileDataFragmentDoc}`;
export const UserBioDataFragmentDoc = gql`
    fragment userBioData on User {
  bio
  createdAt
  handle
  lastCoded
  location
  name
  nameVisibility
  role
  sites {
    github
    linkedIn
    twitter
  }
  website
}
    `;
export const AchievementDataFragmentDoc = gql`
    fragment achievementData on Achievement {
  slug
  name
  badgeIcon
  createdAt
}
    `;
export const CertificateDataFragmentDoc = gql`
    fragment certificateData on Certificate {
  id
  slug
  containerType
  title
  certifiedAt
  lastCompletedAt
  goal
  completionId
  completedPaths {
    title
  }
}
    `;
export const EnrollmentBaseDataFragmentDoc = gql`
    fragment enrollmentBaseData on CatalogContentInterface {
  id
  slug
  title
  pro
}
    `;
export const EnrollmentJourneyDataFragmentDoc = gql`
    fragment enrollmentJourneyData on Journey {
  ...enrollmentBaseData
  type
}
    ${EnrollmentBaseDataFragmentDoc}`;
export const EnrollmentPathDataFragmentDoc = gql`
    fragment enrollmentPathData on Path {
  ...enrollmentBaseData
  type
  goal
}
    ${EnrollmentBaseDataFragmentDoc}`;
export const EnrollmentCourseDataFragmentDoc = gql`
    fragment enrollmentCourseData on Course {
  ...enrollmentBaseData
  type
}
    ${EnrollmentBaseDataFragmentDoc}`;
export const EnrollmentDataFragmentDoc = gql`
    fragment enrollmentData on CatalogContentInterface {
  ...enrollmentJourneyData
  ...enrollmentPathData
  ...enrollmentCourseData
}
    ${EnrollmentJourneyDataFragmentDoc}
${EnrollmentPathDataFragmentDoc}
${EnrollmentCourseDataFragmentDoc}`;
export const ExternalCourseDataFragmentDoc = gql`
    fragment externalCourseData on ExternalCourse {
  id
  slug
  title
  pro
  type
}
    `;
export const EnrollmentExternalCourseDataFragmentDoc = gql`
    fragment enrollmentExternalCourseData on ContentContainer {
  ...externalCourseData
}
    ${ExternalCourseDataFragmentDoc}`;
export const StreakDataFragmentDoc = gql`
    fragment streakData on Streak {
  currentWeeklyStreak
  longestWeeklyStreak
}
    `;
export const PublicProfileDataFragmentDoc = gql`
    fragment publicProfileData on User {
  ...baseProfileData
  ...userBioData
  discourseUsername
  achievements {
    ...achievementData
  }
  certificates {
    ...certificateData
  }
  enrollments {
    ...enrollmentData
    ...enrollmentExternalCourseData
  }
  enrolledJourneys {
    id
  }
  streaks {
    ...streakData
  }
}
    ${BaseProfileDataFragmentDoc}
${UserBioDataFragmentDoc}
${AchievementDataFragmentDoc}
${CertificateDataFragmentDoc}
${EnrollmentDataFragmentDoc}
${EnrollmentExternalCourseDataFragmentDoc}
${StreakDataFragmentDoc}`;
export const NotFoundProfileDataFragmentDoc = gql`
    fragment notFoundProfileData on UserNotFound {
  type: __typename
  message
  unauthorized
}
    `;
export const ProjectProgressDataFragmentDoc = gql`
    fragment projectProgressData on PracticeProjectProgress {
  contentItemId
  started
  completed
}
    `;
export const StaffUsersDocument = gql`
    query staffUsers {
  staffUsers {
    name
    profileImageUrl
    staffDepartment
    staffTitle
    username
  }
}
    `;

/**
 * __useStaffUsersQuery__
 *
 * To run a query within a React component, call `useStaffUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffUsersQuery(baseOptions?: Apollo.QueryHookOptions<StaffUsersQuery, StaffUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffUsersQuery, StaffUsersQueryVariables>(StaffUsersDocument, options);
      }
export function useStaffUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffUsersQuery, StaffUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffUsersQuery, StaffUsersQueryVariables>(StaffUsersDocument, options);
        }
export type StaffUsersQueryHookResult = ReturnType<typeof useStaffUsersQuery>;
export type StaffUsersLazyQueryHookResult = ReturnType<typeof useStaffUsersLazyQuery>;
export type StaffUsersQueryResult = Apollo.QueryResult<StaffUsersQuery, StaffUsersQueryVariables>;
export const AdminDetailsDocument = gql`
    query adminDetails {
  me {
    id
    role
    type
    anonymous
  }
}
    `;

/**
 * __useAdminDetailsQuery__
 *
 * To run a query within a React component, call `useAdminDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AdminDetailsQuery, AdminDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDetailsQuery, AdminDetailsQueryVariables>(AdminDetailsDocument, options);
      }
export function useAdminDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDetailsQuery, AdminDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDetailsQuery, AdminDetailsQueryVariables>(AdminDetailsDocument, options);
        }
export type AdminDetailsQueryHookResult = ReturnType<typeof useAdminDetailsQuery>;
export type AdminDetailsLazyQueryHookResult = ReturnType<typeof useAdminDetailsLazyQuery>;
export type AdminDetailsQueryResult = Apollo.QueryResult<AdminDetailsQuery, AdminDetailsQueryVariables>;
export const BootcampRecordingDocument = gql`
    query bootcampRecording($slug: String!) {
  me {
    id
    anonymous
    username
    email
    enrolledBootcamps {
      slug
    }
  }
  bootcamp(slug: $slug) {
    id
    name
    slug
    startAt
    endAt
    sessions {
      startAt
      duration
      vimeoId
    }
  }
}
    `;

/**
 * __useBootcampRecordingQuery__
 *
 * To run a query within a React component, call `useBootcampRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBootcampRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBootcampRecordingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBootcampRecordingQuery(baseOptions: Apollo.QueryHookOptions<BootcampRecordingQuery, BootcampRecordingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BootcampRecordingQuery, BootcampRecordingQueryVariables>(BootcampRecordingDocument, options);
      }
export function useBootcampRecordingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BootcampRecordingQuery, BootcampRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BootcampRecordingQuery, BootcampRecordingQueryVariables>(BootcampRecordingDocument, options);
        }
export type BootcampRecordingQueryHookResult = ReturnType<typeof useBootcampRecordingQuery>;
export type BootcampRecordingLazyQueryHookResult = ReturnType<typeof useBootcampRecordingLazyQuery>;
export type BootcampRecordingQueryResult = Apollo.QueryResult<BootcampRecordingQuery, BootcampRecordingQueryVariables>;
export const BootcampSessionDocument = gql`
    query bootcampSession($slug: String!) {
  me {
    id
    anonymous
    username
    email
    enrolledBootcamps {
      slug
    }
  }
  bootcamp(slug: $slug) {
    id
    name
    slug
    startAt
    endAt
    sessions {
      startAt
      duration
      meetingId
    }
  }
}
    `;

/**
 * __useBootcampSessionQuery__
 *
 * To run a query within a React component, call `useBootcampSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBootcampSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBootcampSessionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBootcampSessionQuery(baseOptions: Apollo.QueryHookOptions<BootcampSessionQuery, BootcampSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BootcampSessionQuery, BootcampSessionQueryVariables>(BootcampSessionDocument, options);
      }
export function useBootcampSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BootcampSessionQuery, BootcampSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BootcampSessionQuery, BootcampSessionQueryVariables>(BootcampSessionDocument, options);
        }
export type BootcampSessionQueryHookResult = ReturnType<typeof useBootcampSessionQuery>;
export type BootcampSessionLazyQueryHookResult = ReturnType<typeof useBootcampSessionLazyQuery>;
export type BootcampSessionQueryResult = Apollo.QueryResult<BootcampSessionQuery, BootcampSessionQueryVariables>;
export const CareerJourneyProgressDocument = gql`
    query careerJourneyProgress($containerId: String!, $containerType: String!, $includeProgressItems: Boolean) {
  containerProgress(
    containerId: $containerId
    containerType: $containerType
    includeChildCollections: true
    includeProgressItems: $includeProgressItems
  ) {
    id
    completed
    itemsTotal
    itemsCompleted
    percentComplete
    started
    childCollections {
      id
      completed
      itemsTotal
      itemsCompleted
      percentComplete
      started
    }
    contentItemProgresses {
      contentItemId
      completed
      started
    }
  }
}
    `;

/**
 * __useCareerJourneyProgressQuery__
 *
 * To run a query within a React component, call `useCareerJourneyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyProgressQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *      containerType: // value for 'containerType'
 *      includeProgressItems: // value for 'includeProgressItems'
 *   },
 * });
 */
export function useCareerJourneyProgressQuery(baseOptions: Apollo.QueryHookOptions<CareerJourneyProgressQuery, CareerJourneyProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyProgressQuery, CareerJourneyProgressQueryVariables>(CareerJourneyProgressDocument, options);
      }
export function useCareerJourneyProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyProgressQuery, CareerJourneyProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyProgressQuery, CareerJourneyProgressQueryVariables>(CareerJourneyProgressDocument, options);
        }
export type CareerJourneyProgressQueryHookResult = ReturnType<typeof useCareerJourneyProgressQuery>;
export type CareerJourneyProgressLazyQueryHookResult = ReturnType<typeof useCareerJourneyProgressLazyQuery>;
export type CareerJourneyProgressQueryResult = Apollo.QueryResult<CareerJourneyProgressQuery, CareerJourneyProgressQueryVariables>;
export const CareerJourneyResumeLocationDocument = gql`
    query careerJourneyResumeLocation($containerId: String!, $containerType: String!) {
  resumeLocation(containerId: $containerId, containerType: $containerType) {
    resume {
      journeyId
      journeySlug
      pathId
      pathSlug
      trackId
      courseSlug
      moduleId
      moduleSlug
      contentItemId
    }
    error
  }
}
    `;

/**
 * __useCareerJourneyResumeLocationQuery__
 *
 * To run a query within a React component, call `useCareerJourneyResumeLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyResumeLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyResumeLocationQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *      containerType: // value for 'containerType'
 *   },
 * });
 */
export function useCareerJourneyResumeLocationQuery(baseOptions: Apollo.QueryHookOptions<CareerJourneyResumeLocationQuery, CareerJourneyResumeLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyResumeLocationQuery, CareerJourneyResumeLocationQueryVariables>(CareerJourneyResumeLocationDocument, options);
      }
export function useCareerJourneyResumeLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyResumeLocationQuery, CareerJourneyResumeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyResumeLocationQuery, CareerJourneyResumeLocationQueryVariables>(CareerJourneyResumeLocationDocument, options);
        }
export type CareerJourneyResumeLocationQueryHookResult = ReturnType<typeof useCareerJourneyResumeLocationQuery>;
export type CareerJourneyResumeLocationLazyQueryHookResult = ReturnType<typeof useCareerJourneyResumeLocationLazyQuery>;
export type CareerJourneyResumeLocationQueryResult = Apollo.QueryResult<CareerJourneyResumeLocationQuery, CareerJourneyResumeLocationQueryVariables>;
export const JobActionProgressDocument = gql`
    query jobActionProgress($jobActionIds: [String!]!) {
  jobActionProgress(jobActionIds: $jobActionIds) {
    jobActionId
    isComplete
  }
}
    `;

/**
 * __useJobActionProgressQuery__
 *
 * To run a query within a React component, call `useJobActionProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobActionProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobActionProgressQuery({
 *   variables: {
 *      jobActionIds: // value for 'jobActionIds'
 *   },
 * });
 */
export function useJobActionProgressQuery(baseOptions: Apollo.QueryHookOptions<JobActionProgressQuery, JobActionProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobActionProgressQuery, JobActionProgressQueryVariables>(JobActionProgressDocument, options);
      }
export function useJobActionProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobActionProgressQuery, JobActionProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobActionProgressQuery, JobActionProgressQueryVariables>(JobActionProgressDocument, options);
        }
export type JobActionProgressQueryHookResult = ReturnType<typeof useJobActionProgressQuery>;
export type JobActionProgressLazyQueryHookResult = ReturnType<typeof useJobActionProgressLazyQuery>;
export type JobActionProgressQueryResult = Apollo.QueryResult<JobActionProgressQuery, JobActionProgressQueryVariables>;
export const CareerJourneyUserDocument = gql`
    query careerJourneyUser {
  me {
    id
    anonymous
    certificates {
      ...certificatesData
    }
    featureFlags
    proGold
    proSilver
    oneTimeTriggers
    username
    businessUser
  }
}
    ${CertificatesDataFragmentDoc}`;

/**
 * __useCareerJourneyUserQuery__
 *
 * To run a query within a React component, call `useCareerJourneyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareerJourneyUserQuery(baseOptions?: Apollo.QueryHookOptions<CareerJourneyUserQuery, CareerJourneyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyUserQuery, CareerJourneyUserQueryVariables>(CareerJourneyUserDocument, options);
      }
export function useCareerJourneyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyUserQuery, CareerJourneyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyUserQuery, CareerJourneyUserQueryVariables>(CareerJourneyUserDocument, options);
        }
export type CareerJourneyUserQueryHookResult = ReturnType<typeof useCareerJourneyUserQuery>;
export type CareerJourneyUserLazyQueryHookResult = ReturnType<typeof useCareerJourneyUserLazyQuery>;
export type CareerJourneyUserQueryResult = Apollo.QueryResult<CareerJourneyUserQuery, CareerJourneyUserQueryVariables>;
export const CareerJourneyTrackUserDocument = gql`
    query careerJourneyTrackUser {
  me {
    id
    anonymous
    proGold
    proSilver
  }
}
    `;

/**
 * __useCareerJourneyTrackUserQuery__
 *
 * To run a query within a React component, call `useCareerJourneyTrackUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyTrackUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyTrackUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareerJourneyTrackUserQuery(baseOptions?: Apollo.QueryHookOptions<CareerJourneyTrackUserQuery, CareerJourneyTrackUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyTrackUserQuery, CareerJourneyTrackUserQueryVariables>(CareerJourneyTrackUserDocument, options);
      }
export function useCareerJourneyTrackUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyTrackUserQuery, CareerJourneyTrackUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyTrackUserQuery, CareerJourneyTrackUserQueryVariables>(CareerJourneyTrackUserDocument, options);
        }
export type CareerJourneyTrackUserQueryHookResult = ReturnType<typeof useCareerJourneyTrackUserQuery>;
export type CareerJourneyTrackUserLazyQueryHookResult = ReturnType<typeof useCareerJourneyTrackUserLazyQuery>;
export type CareerJourneyTrackUserQueryResult = Apollo.QueryResult<CareerJourneyTrackUserQuery, CareerJourneyTrackUserQueryVariables>;
export const CareerJourneyUserEnrollmentDocument = gql`
    query careerJourneyUserEnrollment {
  me {
    id
    enrolledJourneys {
      createdAt
      id
    }
  }
}
    `;

/**
 * __useCareerJourneyUserEnrollmentQuery__
 *
 * To run a query within a React component, call `useCareerJourneyUserEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyUserEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyUserEnrollmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareerJourneyUserEnrollmentQuery(baseOptions?: Apollo.QueryHookOptions<CareerJourneyUserEnrollmentQuery, CareerJourneyUserEnrollmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyUserEnrollmentQuery, CareerJourneyUserEnrollmentQueryVariables>(CareerJourneyUserEnrollmentDocument, options);
      }
export function useCareerJourneyUserEnrollmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyUserEnrollmentQuery, CareerJourneyUserEnrollmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyUserEnrollmentQuery, CareerJourneyUserEnrollmentQueryVariables>(CareerJourneyUserEnrollmentDocument, options);
        }
export type CareerJourneyUserEnrollmentQueryHookResult = ReturnType<typeof useCareerJourneyUserEnrollmentQuery>;
export type CareerJourneyUserEnrollmentLazyQueryHookResult = ReturnType<typeof useCareerJourneyUserEnrollmentLazyQuery>;
export type CareerJourneyUserEnrollmentQueryResult = Apollo.QueryResult<CareerJourneyUserEnrollmentQuery, CareerJourneyUserEnrollmentQueryVariables>;
export const CareerJourneyCumulativeAssessmentAttemptSummaryDocument = gql`
    query careerJourneyCumulativeAssessmentAttemptSummary($containerId: String!) {
  cumulativeAssessmentAttemptSummary(containerId: $containerId) {
    userId
    containerId
    containerType
    totalAssessmentsPassed
    cumulativeAssessmentAttempts {
      ...sectionAssessmentAttemptData
    }
    errorMessage
  }
}
    ${SectionAssessmentAttemptDataFragmentDoc}`;

/**
 * __useCareerJourneyCumulativeAssessmentAttemptSummaryQuery__
 *
 * To run a query within a React component, call `useCareerJourneyCumulativeAssessmentAttemptSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerJourneyCumulativeAssessmentAttemptSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerJourneyCumulativeAssessmentAttemptSummaryQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useCareerJourneyCumulativeAssessmentAttemptSummaryQuery(baseOptions: Apollo.QueryHookOptions<CareerJourneyCumulativeAssessmentAttemptSummaryQuery, CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerJourneyCumulativeAssessmentAttemptSummaryQuery, CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables>(CareerJourneyCumulativeAssessmentAttemptSummaryDocument, options);
      }
export function useCareerJourneyCumulativeAssessmentAttemptSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerJourneyCumulativeAssessmentAttemptSummaryQuery, CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerJourneyCumulativeAssessmentAttemptSummaryQuery, CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables>(CareerJourneyCumulativeAssessmentAttemptSummaryDocument, options);
        }
export type CareerJourneyCumulativeAssessmentAttemptSummaryQueryHookResult = ReturnType<typeof useCareerJourneyCumulativeAssessmentAttemptSummaryQuery>;
export type CareerJourneyCumulativeAssessmentAttemptSummaryLazyQueryHookResult = ReturnType<typeof useCareerJourneyCumulativeAssessmentAttemptSummaryLazyQuery>;
export type CareerJourneyCumulativeAssessmentAttemptSummaryQueryResult = Apollo.QueryResult<CareerJourneyCumulativeAssessmentAttemptSummaryQuery, CareerJourneyCumulativeAssessmentAttemptSummaryQueryVariables>;
export const EnrollInJourneyDocument = gql`
    mutation EnrollInJourney($container: EnrollmentInput!) {
  enrollContainer(container: $container) {
    enrollmentId
    enrollmentType
    error
  }
}
    `;
export type EnrollInJourneyMutationFn = Apollo.MutationFunction<EnrollInJourneyMutation, EnrollInJourneyMutationVariables>;

/**
 * __useEnrollInJourneyMutation__
 *
 * To run a mutation, you first call `useEnrollInJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollInJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollInJourneyMutation, { data, loading, error }] = useEnrollInJourneyMutation({
 *   variables: {
 *      container: // value for 'container'
 *   },
 * });
 */
export function useEnrollInJourneyMutation(baseOptions?: Apollo.MutationHookOptions<EnrollInJourneyMutation, EnrollInJourneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnrollInJourneyMutation, EnrollInJourneyMutationVariables>(EnrollInJourneyDocument, options);
      }
export type EnrollInJourneyMutationHookResult = ReturnType<typeof useEnrollInJourneyMutation>;
export type EnrollInJourneyMutationResult = Apollo.MutationResult<EnrollInJourneyMutation>;
export type EnrollInJourneyMutationOptions = Apollo.BaseMutationOptions<EnrollInJourneyMutation, EnrollInJourneyMutationVariables>;
export const MarkJobActionDocument = gql`
    mutation markJobAction($jobActionId: String!, $isComplete: Boolean!) {
  markJobAction(jobActionId: $jobActionId, isComplete: $isComplete) {
    __typename
    jobActionProgress {
      __typename
      jobActionId
      isComplete
    }
    error
  }
}
    `;
export type MarkJobActionMutationFn = Apollo.MutationFunction<MarkJobActionMutation, MarkJobActionMutationVariables>;

/**
 * __useMarkJobActionMutation__
 *
 * To run a mutation, you first call `useMarkJobActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkJobActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markJobActionMutation, { data, loading, error }] = useMarkJobActionMutation({
 *   variables: {
 *      jobActionId: // value for 'jobActionId'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useMarkJobActionMutation(baseOptions?: Apollo.MutationHookOptions<MarkJobActionMutation, MarkJobActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkJobActionMutation, MarkJobActionMutationVariables>(MarkJobActionDocument, options);
      }
export type MarkJobActionMutationHookResult = ReturnType<typeof useMarkJobActionMutation>;
export type MarkJobActionMutationResult = Apollo.MutationResult<MarkJobActionMutation>;
export type MarkJobActionMutationOptions = Apollo.BaseMutationOptions<MarkJobActionMutation, MarkJobActionMutationVariables>;
export const ResetContainerProgressDocument = gql`
    mutation resetContainerProgress($containers: [ResetProgressInput!]!) {
  resetContainerProgress(containers: $containers) {
    containersReset
  }
}
    `;
export type ResetContainerProgressMutationFn = Apollo.MutationFunction<ResetContainerProgressMutation, ResetContainerProgressMutationVariables>;

/**
 * __useResetContainerProgressMutation__
 *
 * To run a mutation, you first call `useResetContainerProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetContainerProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetContainerProgressMutation, { data, loading, error }] = useResetContainerProgressMutation({
 *   variables: {
 *      containers: // value for 'containers'
 *   },
 * });
 */
export function useResetContainerProgressMutation(baseOptions?: Apollo.MutationHookOptions<ResetContainerProgressMutation, ResetContainerProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetContainerProgressMutation, ResetContainerProgressMutationVariables>(ResetContainerProgressDocument, options);
      }
export type ResetContainerProgressMutationHookResult = ReturnType<typeof useResetContainerProgressMutation>;
export type ResetContainerProgressMutationResult = Apollo.MutationResult<ResetContainerProgressMutation>;
export type ResetContainerProgressMutationOptions = Apollo.BaseMutationOptions<ResetContainerProgressMutation, ResetContainerProgressMutationVariables>;
export const TransferCodeChallengeDocument = gql`
    mutation transferCodeChallenge($assessmentSlug: String!, $codeChallengeAttemptId: String!) {
  transferCodeChallenge(
    assessmentSlug: $assessmentSlug
    codeChallengeAttemptId: $codeChallengeAttemptId
  ) {
    attempt {
      ...sectionAssessmentAttemptData
    }
    error
  }
}
    ${SectionAssessmentAttemptDataFragmentDoc}`;
export type TransferCodeChallengeMutationFn = Apollo.MutationFunction<TransferCodeChallengeMutation, TransferCodeChallengeMutationVariables>;

/**
 * __useTransferCodeChallengeMutation__
 *
 * To run a mutation, you first call `useTransferCodeChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferCodeChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferCodeChallengeMutation, { data, loading, error }] = useTransferCodeChallengeMutation({
 *   variables: {
 *      assessmentSlug: // value for 'assessmentSlug'
 *      codeChallengeAttemptId: // value for 'codeChallengeAttemptId'
 *   },
 * });
 */
export function useTransferCodeChallengeMutation(baseOptions?: Apollo.MutationHookOptions<TransferCodeChallengeMutation, TransferCodeChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferCodeChallengeMutation, TransferCodeChallengeMutationVariables>(TransferCodeChallengeDocument, options);
      }
export type TransferCodeChallengeMutationHookResult = ReturnType<typeof useTransferCodeChallengeMutation>;
export type TransferCodeChallengeMutationResult = Apollo.MutationResult<TransferCodeChallengeMutation>;
export type TransferCodeChallengeMutationOptions = Apollo.BaseMutationOptions<TransferCodeChallengeMutation, TransferCodeChallengeMutationVariables>;
export const VerifiedStudentDocument = gql`
    query verifiedStudent {
  me {
    id
    verifiedStudent
  }
}
    `;

/**
 * __useVerifiedStudentQuery__
 *
 * To run a query within a React component, call `useVerifiedStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifiedStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifiedStudentQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifiedStudentQuery(baseOptions?: Apollo.QueryHookOptions<VerifiedStudentQuery, VerifiedStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifiedStudentQuery, VerifiedStudentQueryVariables>(VerifiedStudentDocument, options);
      }
export function useVerifiedStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifiedStudentQuery, VerifiedStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifiedStudentQuery, VerifiedStudentQueryVariables>(VerifiedStudentDocument, options);
        }
export type VerifiedStudentQueryHookResult = ReturnType<typeof useVerifiedStudentQuery>;
export type VerifiedStudentLazyQueryHookResult = ReturnType<typeof useVerifiedStudentLazyQuery>;
export type VerifiedStudentQueryResult = Apollo.QueryResult<VerifiedStudentQuery, VerifiedStudentQueryVariables>;
export const ChallengesUserDocument = gql`
    query challengesUser {
  me {
    id
    proGold
    proSilver
  }
}
    `;

/**
 * __useChallengesUserQuery__
 *
 * To run a query within a React component, call `useChallengesUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengesUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengesUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useChallengesUserQuery(baseOptions?: Apollo.QueryHookOptions<ChallengesUserQuery, ChallengesUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengesUserQuery, ChallengesUserQueryVariables>(ChallengesUserDocument, options);
      }
export function useChallengesUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengesUserQuery, ChallengesUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengesUserQuery, ChallengesUserQueryVariables>(ChallengesUserDocument, options);
        }
export type ChallengesUserQueryHookResult = ReturnType<typeof useChallengesUserQuery>;
export type ChallengesUserLazyQueryHookResult = ReturnType<typeof useChallengesUserLazyQuery>;
export type ChallengesUserQueryResult = Apollo.QueryResult<ChallengesUserQuery, ChallengesUserQueryVariables>;
export const CheatsheetCategoriesDocument = gql`
    query cheatsheetCategories {
  catalog {
    filters {
      slug
      title
      items {
        slug
        title
      }
    }
  }
}
    `;

/**
 * __useCheatsheetCategoriesQuery__
 *
 * To run a query within a React component, call `useCheatsheetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheatsheetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheatsheetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheatsheetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CheatsheetCategoriesQuery, CheatsheetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheatsheetCategoriesQuery, CheatsheetCategoriesQueryVariables>(CheatsheetCategoriesDocument, options);
      }
export function useCheatsheetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheatsheetCategoriesQuery, CheatsheetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheatsheetCategoriesQuery, CheatsheetCategoriesQueryVariables>(CheatsheetCategoriesDocument, options);
        }
export type CheatsheetCategoriesQueryHookResult = ReturnType<typeof useCheatsheetCategoriesQuery>;
export type CheatsheetCategoriesLazyQueryHookResult = ReturnType<typeof useCheatsheetCategoriesLazyQuery>;
export type CheatsheetCategoriesQueryResult = Apollo.QueryResult<CheatsheetCategoriesQuery, CheatsheetCategoriesQueryVariables>;
export const CheatsheetCategoriesSitemapDocument = gql`
    query cheatsheetCategoriesSitemap {
  catalog {
    filters {
      slug
      items {
        slug
      }
    }
  }
}
    `;

/**
 * __useCheatsheetCategoriesSitemapQuery__
 *
 * To run a query within a React component, call `useCheatsheetCategoriesSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheatsheetCategoriesSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheatsheetCategoriesSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheatsheetCategoriesSitemapQuery(baseOptions?: Apollo.QueryHookOptions<CheatsheetCategoriesSitemapQuery, CheatsheetCategoriesSitemapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheatsheetCategoriesSitemapQuery, CheatsheetCategoriesSitemapQueryVariables>(CheatsheetCategoriesSitemapDocument, options);
      }
export function useCheatsheetCategoriesSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheatsheetCategoriesSitemapQuery, CheatsheetCategoriesSitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheatsheetCategoriesSitemapQuery, CheatsheetCategoriesSitemapQueryVariables>(CheatsheetCategoriesSitemapDocument, options);
        }
export type CheatsheetCategoriesSitemapQueryHookResult = ReturnType<typeof useCheatsheetCategoriesSitemapQuery>;
export type CheatsheetCategoriesSitemapLazyQueryHookResult = ReturnType<typeof useCheatsheetCategoriesSitemapLazyQuery>;
export type CheatsheetCategoriesSitemapQueryResult = Apollo.QueryResult<CheatsheetCategoriesSitemapQuery, CheatsheetCategoriesSitemapQueryVariables>;
export const CheckoutDocument = gql`
    query checkout($noTrial: Boolean, $referredByCode: Boolean, $isPwa: Boolean, $studentPlans: Boolean) {
  me {
    id
    anonymous
    pro
    verified
    email
    unconfirmedEmail
    verifiedStudent
    subscriptionPaused
    mbgEligible
    studentVerification {
      verificationId
    }
  }
  pricing(
    noTrial: $noTrial
    referredByCode: $referredByCode
    isPwa: $isPwa
    studentPlans: $studentPlans
  ) {
    heading
    description
    currency
    subdescription
    backgroundImage {
      image
    }
    logos {
      image
      alt
    }
    products {
      ...productData
    }
  }
}
    ${ProductDataFragmentDoc}`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      noTrial: // value for 'noTrial'
 *      referredByCode: // value for 'referredByCode'
 *      isPwa: // value for 'isPwa'
 *      studentPlans: // value for 'studentPlans'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const BootcampPageDocument = gql`
    query bootcampPage($purchaseType: String!, $slug: String!) {
  me {
    id
    anonymous
    pro
    enrolledBootcamps {
      slug
    }
  }
  bootcamp(slug: $slug) {
    id
    name
    slug
    startAt
    registrationStartAt
    registrationEndAt
    startAt
    endAt
    enrollmentLimit
    sessions {
      startAt
      duration
    }
  }
  pay(purchaseType: $purchaseType, slug: $slug) {
    id
    slug
    price
    meta
    error
  }
}
    `;

/**
 * __useBootcampPageQuery__
 *
 * To run a query within a React component, call `useBootcampPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBootcampPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBootcampPageQuery({
 *   variables: {
 *      purchaseType: // value for 'purchaseType'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBootcampPageQuery(baseOptions: Apollo.QueryHookOptions<BootcampPageQuery, BootcampPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BootcampPageQuery, BootcampPageQueryVariables>(BootcampPageDocument, options);
      }
export function useBootcampPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BootcampPageQuery, BootcampPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BootcampPageQuery, BootcampPageQueryVariables>(BootcampPageDocument, options);
        }
export type BootcampPageQueryHookResult = ReturnType<typeof useBootcampPageQuery>;
export type BootcampPageLazyQueryHookResult = ReturnType<typeof useBootcampPageLazyQuery>;
export type BootcampPageQueryResult = Apollo.QueryResult<BootcampPageQuery, BootcampPageQueryVariables>;
export const ClpUserDocument = gql`
    query clpUser {
  me {
    id
    pro
    proSilver
    proGold
    anonymous
    enrollments {
      ... on Course {
        id
      }
    }
    experiments
    freeProjectsTaken
    freeQuizzesTaken
  }
}
    `;

/**
 * __useClpUserQuery__
 *
 * To run a query within a React component, call `useClpUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useClpUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClpUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useClpUserQuery(baseOptions?: Apollo.QueryHookOptions<ClpUserQuery, ClpUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClpUserQuery, ClpUserQueryVariables>(ClpUserDocument, options);
      }
export function useClpUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClpUserQuery, ClpUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClpUserQuery, ClpUserQueryVariables>(ClpUserDocument, options);
        }
export type ClpUserQueryHookResult = ReturnType<typeof useClpUserQuery>;
export type ClpUserLazyQueryHookResult = ReturnType<typeof useClpUserLazyQuery>;
export type ClpUserQueryResult = Apollo.QueryResult<ClpUserQuery, ClpUserQueryVariables>;
export const ExtCourseUserDocument = gql`
    query extCourseUser {
  me {
    id
    pro
    anonymous
  }
}
    `;

/**
 * __useExtCourseUserQuery__
 *
 * To run a query within a React component, call `useExtCourseUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtCourseUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtCourseUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useExtCourseUserQuery(baseOptions?: Apollo.QueryHookOptions<ExtCourseUserQuery, ExtCourseUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExtCourseUserQuery, ExtCourseUserQueryVariables>(ExtCourseUserDocument, options);
      }
export function useExtCourseUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExtCourseUserQuery, ExtCourseUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExtCourseUserQuery, ExtCourseUserQueryVariables>(ExtCourseUserDocument, options);
        }
export type ExtCourseUserQueryHookResult = ReturnType<typeof useExtCourseUserQuery>;
export type ExtCourseUserLazyQueryHookResult = ReturnType<typeof useExtCourseUserLazyQuery>;
export type ExtCourseUserQueryResult = Apollo.QueryResult<ExtCourseUserQuery, ExtCourseUserQueryVariables>;
export const UserWithEnrollmentsDocument = gql`
    query userWithEnrollments {
  me {
    id
    pro
    anonymous
    proGold
    proSilver
    businessUser
    skillsoftUser
    mbgEligible
    enrollments {
      ... on Journey {
        id
        type
      }
      ... on Path {
        id
        type
      }
    }
  }
}
    `;

/**
 * __useUserWithEnrollmentsQuery__
 *
 * To run a query within a React component, call `useUserWithEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithEnrollmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithEnrollmentsQuery(baseOptions?: Apollo.QueryHookOptions<UserWithEnrollmentsQuery, UserWithEnrollmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithEnrollmentsQuery, UserWithEnrollmentsQueryVariables>(UserWithEnrollmentsDocument, options);
      }
export function useUserWithEnrollmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithEnrollmentsQuery, UserWithEnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithEnrollmentsQuery, UserWithEnrollmentsQueryVariables>(UserWithEnrollmentsDocument, options);
        }
export type UserWithEnrollmentsQueryHookResult = ReturnType<typeof useUserWithEnrollmentsQuery>;
export type UserWithEnrollmentsLazyQueryHookResult = ReturnType<typeof useUserWithEnrollmentsLazyQuery>;
export type UserWithEnrollmentsQueryResult = Apollo.QueryResult<UserWithEnrollmentsQuery, UserWithEnrollmentsQueryVariables>;
export const JobResumeDocument = gql`
    query jobResume {
  me {
    id
    proGold
    proSilver
    anonymous
    oneTimeTriggers
    jobResume {
      id
      userInput
    }
    businessUser
  }
}
    `;

/**
 * __useJobResumeQuery__
 *
 * To run a query within a React component, call `useJobResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobResumeQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobResumeQuery(baseOptions?: Apollo.QueryHookOptions<JobResumeQuery, JobResumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobResumeQuery, JobResumeQueryVariables>(JobResumeDocument, options);
      }
export function useJobResumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobResumeQuery, JobResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobResumeQuery, JobResumeQueryVariables>(JobResumeDocument, options);
        }
export type JobResumeQueryHookResult = ReturnType<typeof useJobResumeQuery>;
export type JobResumeLazyQueryHookResult = ReturnType<typeof useJobResumeLazyQuery>;
export type JobResumeQueryResult = Apollo.QueryResult<JobResumeQuery, JobResumeQueryVariables>;
export const JobAnalysisDocument = gql`
    query jobAnalysis($id: ID!) {
  jobAnalysis(id: $id) {
    ...jobAnalysisData
  }
  me {
    id
    proGold
    jobResume {
      id
    }
  }
}
    ${JobAnalysisDataFragmentDoc}`;

/**
 * __useJobAnalysisQuery__
 *
 * To run a query within a React component, call `useJobAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobAnalysisQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobAnalysisQuery(baseOptions: Apollo.QueryHookOptions<JobAnalysisQuery, JobAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobAnalysisQuery, JobAnalysisQueryVariables>(JobAnalysisDocument, options);
      }
export function useJobAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobAnalysisQuery, JobAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobAnalysisQuery, JobAnalysisQueryVariables>(JobAnalysisDocument, options);
        }
export type JobAnalysisQueryHookResult = ReturnType<typeof useJobAnalysisQuery>;
export type JobAnalysisLazyQueryHookResult = ReturnType<typeof useJobAnalysisLazyQuery>;
export type JobAnalysisQueryResult = Apollo.QueryResult<JobAnalysisQuery, JobAnalysisQueryVariables>;
export const CareerReadinessDocument = gql`
    query careerReadiness {
  me {
    id
    certificates {
      ...certificateData
    }
    enrollments {
      ...enrollmentReadiness
    }
    jobAnalyses {
      ...jobAnalysisData
    }
    jobResume {
      ...jobResumeData
    }
    proGold
    proSilver
    anonymous
    businessUser
  }
}
    ${CertificateDataFragmentDoc}
${EnrollmentReadinessFragmentDoc}
${JobAnalysisDataFragmentDoc}
${JobResumeDataFragmentDoc}`;

/**
 * __useCareerReadinessQuery__
 *
 * To run a query within a React component, call `useCareerReadinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerReadinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerReadinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareerReadinessQuery(baseOptions?: Apollo.QueryHookOptions<CareerReadinessQuery, CareerReadinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerReadinessQuery, CareerReadinessQueryVariables>(CareerReadinessDocument, options);
      }
export function useCareerReadinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerReadinessQuery, CareerReadinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerReadinessQuery, CareerReadinessQueryVariables>(CareerReadinessDocument, options);
        }
export type CareerReadinessQueryHookResult = ReturnType<typeof useCareerReadinessQuery>;
export type CareerReadinessLazyQueryHookResult = ReturnType<typeof useCareerReadinessLazyQuery>;
export type CareerReadinessQueryResult = Apollo.QueryResult<CareerReadinessQuery, CareerReadinessQueryVariables>;
export const UserAnonymousDocument = gql`
    query userAnonymous {
  me {
    id
    proGold
    proSilver
    anonymous
  }
}
    `;

/**
 * __useUserAnonymousQuery__
 *
 * To run a query within a React component, call `useUserAnonymousQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnonymousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnonymousQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAnonymousQuery(baseOptions?: Apollo.QueryHookOptions<UserAnonymousQuery, UserAnonymousQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAnonymousQuery, UserAnonymousQueryVariables>(UserAnonymousDocument, options);
      }
export function useUserAnonymousLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAnonymousQuery, UserAnonymousQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAnonymousQuery, UserAnonymousQueryVariables>(UserAnonymousDocument, options);
        }
export type UserAnonymousQueryHookResult = ReturnType<typeof useUserAnonymousQuery>;
export type UserAnonymousLazyQueryHookResult = ReturnType<typeof useUserAnonymousLazyQuery>;
export type UserAnonymousQueryResult = Apollo.QueryResult<UserAnonymousQuery, UserAnonymousQueryVariables>;
export const PayServerDocument = gql`
    query payServer($purchaseType: String!, $slug: String!) {
  me {
    id
    anonymous
    pro
    verified
    email
    unconfirmedEmail
  }
  pay(purchaseType: $purchaseType, slug: $slug) {
    id
    slug
    heading
    price
    meta
    error
  }
}
    `;

/**
 * __usePayServerQuery__
 *
 * To run a query within a React component, call `usePayServerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayServerQuery({
 *   variables: {
 *      purchaseType: // value for 'purchaseType'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePayServerQuery(baseOptions: Apollo.QueryHookOptions<PayServerQuery, PayServerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayServerQuery, PayServerQueryVariables>(PayServerDocument, options);
      }
export function usePayServerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayServerQuery, PayServerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayServerQuery, PayServerQueryVariables>(PayServerDocument, options);
        }
export type PayServerQueryHookResult = ReturnType<typeof usePayServerQuery>;
export type PayServerLazyQueryHookResult = ReturnType<typeof usePayServerLazyQuery>;
export type PayServerQueryResult = Apollo.QueryResult<PayServerQuery, PayServerQueryVariables>;
export const BrandedBannerUserDocument = gql`
    query brandedBannerUser {
  me {
    ... on User {
      id
      role
      pro
      type
    }
  }
}
    `;

/**
 * __useBrandedBannerUserQuery__
 *
 * To run a query within a React component, call `useBrandedBannerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandedBannerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandedBannerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandedBannerUserQuery(baseOptions?: Apollo.QueryHookOptions<BrandedBannerUserQuery, BrandedBannerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandedBannerUserQuery, BrandedBannerUserQueryVariables>(BrandedBannerUserDocument, options);
      }
export function useBrandedBannerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandedBannerUserQuery, BrandedBannerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandedBannerUserQuery, BrandedBannerUserQueryVariables>(BrandedBannerUserDocument, options);
        }
export type BrandedBannerUserQueryHookResult = ReturnType<typeof useBrandedBannerUserQuery>;
export type BrandedBannerUserLazyQueryHookResult = ReturnType<typeof useBrandedBannerUserLazyQuery>;
export type BrandedBannerUserQueryResult = Apollo.QueryResult<BrandedBannerUserQuery, BrandedBannerUserQueryVariables>;
export const PastDueModalUserDocument = gql`
    query pastDueModalUser {
  me {
    id
    pastDue
    pastDueErrCode
    latestPaymentMethod
    provider
    currentRenewalDate
    paymentLink
    cancellationLink
    inPreDunning
  }
}
    `;

/**
 * __usePastDueModalUserQuery__
 *
 * To run a query within a React component, call `usePastDueModalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastDueModalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastDueModalUserQuery({
 *   variables: {
 *   },
 * });
 */
export function usePastDueModalUserQuery(baseOptions?: Apollo.QueryHookOptions<PastDueModalUserQuery, PastDueModalUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastDueModalUserQuery, PastDueModalUserQueryVariables>(PastDueModalUserDocument, options);
      }
export function usePastDueModalUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastDueModalUserQuery, PastDueModalUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastDueModalUserQuery, PastDueModalUserQueryVariables>(PastDueModalUserDocument, options);
        }
export type PastDueModalUserQueryHookResult = ReturnType<typeof usePastDueModalUserQuery>;
export type PastDueModalUserLazyQueryHookResult = ReturnType<typeof usePastDueModalUserLazyQuery>;
export type PastDueModalUserQueryResult = Apollo.QueryResult<PastDueModalUserQuery, PastDueModalUserQueryVariables>;
export const FeatureFlagsAndExperimentsDocument = gql`
    query featureFlagsAndExperiments {
  me {
    id
    username
    anonymous
    featureFlags
    experiments
  }
}
    `;

/**
 * __useFeatureFlagsAndExperimentsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsAndExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsAndExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsAndExperimentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsAndExperimentsQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagsAndExperimentsQuery, FeatureFlagsAndExperimentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagsAndExperimentsQuery, FeatureFlagsAndExperimentsQueryVariables>(FeatureFlagsAndExperimentsDocument, options);
      }
export function useFeatureFlagsAndExperimentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsAndExperimentsQuery, FeatureFlagsAndExperimentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagsAndExperimentsQuery, FeatureFlagsAndExperimentsQueryVariables>(FeatureFlagsAndExperimentsDocument, options);
        }
export type FeatureFlagsAndExperimentsQueryHookResult = ReturnType<typeof useFeatureFlagsAndExperimentsQuery>;
export type FeatureFlagsAndExperimentsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsAndExperimentsLazyQuery>;
export type FeatureFlagsAndExperimentsQueryResult = Apollo.QueryResult<FeatureFlagsAndExperimentsQuery, FeatureFlagsAndExperimentsQueryVariables>;
export const PricingDocument = gql`
    query pricing($noTrial: Boolean, $referredByCode: Boolean, $isPwa: Boolean) {
  me {
    id
    anonymous
    pro
    proSilver
    verified
    email
    unconfirmedEmail
    mbgEligible
  }
  pricing(noTrial: $noTrial, referredByCode: $referredByCode, isPwa: $isPwa) {
    description
    currency
    subdescription
    backgroundImage {
      image
    }
    logos {
      image
      alt
    }
    products {
      ...productData
    }
  }
}
    ${ProductDataFragmentDoc}`;

/**
 * __usePricingQuery__
 *
 * To run a query within a React component, call `usePricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingQuery({
 *   variables: {
 *      noTrial: // value for 'noTrial'
 *      referredByCode: // value for 'referredByCode'
 *      isPwa: // value for 'isPwa'
 *   },
 * });
 */
export function usePricingQuery(baseOptions?: Apollo.QueryHookOptions<PricingQuery, PricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
      }
export function usePricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingQuery, PricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
        }
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingQueryResult = Apollo.QueryResult<PricingQuery, PricingQueryVariables>;
export const ProfileEditPageDocument = gql`
    query profileEditPage($username: String!) {
  me {
    id
    businessUser
  }
  profile(username: $username) {
    ... on User {
      ...baseProfileData
      ...userBioData
      handles {
        github
        linkedIn
        twitter
      }
      verified
      visibility
    }
  }
}
    ${BaseProfileDataFragmentDoc}
${UserBioDataFragmentDoc}`;

/**
 * __useProfileEditPageQuery__
 *
 * To run a query within a React component, call `useProfileEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEditPageQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfileEditPageQuery(baseOptions: Apollo.QueryHookOptions<ProfileEditPageQuery, ProfileEditPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileEditPageQuery, ProfileEditPageQueryVariables>(ProfileEditPageDocument, options);
      }
export function useProfileEditPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileEditPageQuery, ProfileEditPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileEditPageQuery, ProfileEditPageQueryVariables>(ProfileEditPageDocument, options);
        }
export type ProfileEditPageQueryHookResult = ReturnType<typeof useProfileEditPageQuery>;
export type ProfileEditPageLazyQueryHookResult = ReturnType<typeof useProfileEditPageLazyQuery>;
export type ProfileEditPageQueryResult = Apollo.QueryResult<ProfileEditPageQuery, ProfileEditPageQueryVariables>;
export const EditUserDocument = gql`
    mutation EditUser($userData: UserInput!, $id: ID!) {
  editUser(userData: $userData, id: $id) {
    errors {
      message
    }
    user {
      bio
      id
      location
      name
      nameVisibility
      profileImageUrl
      handles {
        github
        linkedIn
        twitter
      }
      username
      verified
      visibility
      website
    }
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const ProfilePageDocument = gql`
    query profilePage($username: String!) {
  me {
    id
    businessUser
    proGold
  }
  profile(username: $username) {
    ...publicProfileData
    ...privateProfileData
    ...notFoundProfileData
  }
}
    ${PublicProfileDataFragmentDoc}
${PrivateProfileDataFragmentDoc}
${NotFoundProfileDataFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const ProfileMeDocument = gql`
    query profileMe {
  me {
    id
    username
    anonymous
  }
}
    `;

/**
 * __useProfileMeQuery__
 *
 * To run a query within a React component, call `useProfileMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileMeQuery(baseOptions?: Apollo.QueryHookOptions<ProfileMeQuery, ProfileMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileMeQuery, ProfileMeQueryVariables>(ProfileMeDocument, options);
      }
export function useProfileMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileMeQuery, ProfileMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileMeQuery, ProfileMeQueryVariables>(ProfileMeDocument, options);
        }
export type ProfileMeQueryHookResult = ReturnType<typeof useProfileMeQuery>;
export type ProfileMeLazyQueryHookResult = ReturnType<typeof useProfileMeLazyQuery>;
export type ProfileMeQueryResult = Apollo.QueryResult<ProfileMeQuery, ProfileMeQueryVariables>;
export const CreateDiscourseUserDocument = gql`
    mutation createDiscourseUser {
  createDiscourseUser {
    discourseUsername
  }
}
    `;
export type CreateDiscourseUserMutationFn = Apollo.MutationFunction<CreateDiscourseUserMutation, CreateDiscourseUserMutationVariables>;

/**
 * __useCreateDiscourseUserMutation__
 *
 * To run a mutation, you first call `useCreateDiscourseUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscourseUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscourseUserMutation, { data, loading, error }] = useCreateDiscourseUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDiscourseUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscourseUserMutation, CreateDiscourseUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscourseUserMutation, CreateDiscourseUserMutationVariables>(CreateDiscourseUserDocument, options);
      }
export type CreateDiscourseUserMutationHookResult = ReturnType<typeof useCreateDiscourseUserMutation>;
export type CreateDiscourseUserMutationResult = Apollo.MutationResult<CreateDiscourseUserMutation>;
export type CreateDiscourseUserMutationOptions = Apollo.BaseMutationOptions<CreateDiscourseUserMutation, CreateDiscourseUserMutationVariables>;
export const ProgressDocument = gql`
    query progress($userId: String!, $containerType: String!, $containerId: String!) {
  containerProgress(
    userId: $userId
    containerType: $containerType
    containerId: $containerId
  ) {
    percentComplete
  }
}
    `;

/**
 * __useProgressQuery__
 *
 * To run a query within a React component, call `useProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      containerType: // value for 'containerType'
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useProgressQuery(baseOptions: Apollo.QueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, options);
      }
export function useProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, options);
        }
export type ProgressQueryHookResult = ReturnType<typeof useProgressQuery>;
export type ProgressLazyQueryHookResult = ReturnType<typeof useProgressLazyQuery>;
export type ProgressQueryResult = Apollo.QueryResult<ProgressQuery, ProgressQueryVariables>;
export const UserIsProDocument = gql`
    query userIsPro {
  me {
    id
    pro
  }
}
    `;

/**
 * __useUserIsProQuery__
 *
 * To run a query within a React component, call `useUserIsProQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsProQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsProQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIsProQuery(baseOptions?: Apollo.QueryHookOptions<UserIsProQuery, UserIsProQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIsProQuery, UserIsProQueryVariables>(UserIsProDocument, options);
      }
export function useUserIsProLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIsProQuery, UserIsProQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIsProQuery, UserIsProQueryVariables>(UserIsProDocument, options);
        }
export type UserIsProQueryHookResult = ReturnType<typeof useUserIsProQuery>;
export type UserIsProLazyQueryHookResult = ReturnType<typeof useUserIsProLazyQuery>;
export type UserIsProQueryResult = Apollo.QueryResult<UserIsProQuery, UserIsProQueryVariables>;
export const PracticeProjectsProgressDocument = gql`
    query practiceProjectsProgress($projectIds: [String!]!) {
  practiceProjectsProgress(projectIds: $projectIds) {
    ...projectProgressData
  }
}
    ${ProjectProgressDataFragmentDoc}`;

/**
 * __usePracticeProjectsProgressQuery__
 *
 * To run a query within a React component, call `usePracticeProjectsProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeProjectsProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeProjectsProgressQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function usePracticeProjectsProgressQuery(baseOptions: Apollo.QueryHookOptions<PracticeProjectsProgressQuery, PracticeProjectsProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PracticeProjectsProgressQuery, PracticeProjectsProgressQueryVariables>(PracticeProjectsProgressDocument, options);
      }
export function usePracticeProjectsProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PracticeProjectsProgressQuery, PracticeProjectsProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PracticeProjectsProgressQuery, PracticeProjectsProgressQueryVariables>(PracticeProjectsProgressDocument, options);
        }
export type PracticeProjectsProgressQueryHookResult = ReturnType<typeof usePracticeProjectsProgressQuery>;
export type PracticeProjectsProgressLazyQueryHookResult = ReturnType<typeof usePracticeProjectsProgressLazyQuery>;
export type PracticeProjectsProgressQueryResult = Apollo.QueryResult<PracticeProjectsProgressQuery, PracticeProjectsProgressQueryVariables>;
export const EditUserOneTimeTriggersDocument = gql`
    mutation EditUserOneTimeTriggers($triggerName: String!, $markTrigger: Boolean!) {
  editUserOneTimeTriggers(triggerName: $triggerName, markTrigger: $markTrigger) {
    oneTimeTriggers
  }
}
    `;
export type EditUserOneTimeTriggersMutationFn = Apollo.MutationFunction<EditUserOneTimeTriggersMutation, EditUserOneTimeTriggersMutationVariables>;

/**
 * __useEditUserOneTimeTriggersMutation__
 *
 * To run a mutation, you first call `useEditUserOneTimeTriggersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserOneTimeTriggersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserOneTimeTriggersMutation, { data, loading, error }] = useEditUserOneTimeTriggersMutation({
 *   variables: {
 *      triggerName: // value for 'triggerName'
 *      markTrigger: // value for 'markTrigger'
 *   },
 * });
 */
export function useEditUserOneTimeTriggersMutation(baseOptions?: Apollo.MutationHookOptions<EditUserOneTimeTriggersMutation, EditUserOneTimeTriggersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserOneTimeTriggersMutation, EditUserOneTimeTriggersMutationVariables>(EditUserOneTimeTriggersDocument, options);
      }
export type EditUserOneTimeTriggersMutationHookResult = ReturnType<typeof useEditUserOneTimeTriggersMutation>;
export type EditUserOneTimeTriggersMutationResult = Apollo.MutationResult<EditUserOneTimeTriggersMutation>;
export type EditUserOneTimeTriggersMutationOptions = Apollo.BaseMutationOptions<EditUserOneTimeTriggersMutation, EditUserOneTimeTriggersMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseUserInterface": [
      "PrivateUser",
      "User"
    ],
    "CatalogContentInterface": [
      "Course",
      "Journey",
      "Path"
    ],
    "ContentContainer": [
      "CatalogCategory",
      "Course",
      "ExternalCourse",
      "Journey",
      "Path"
    ],
    "PaginatedData": [
      "BusinessCohort",
      "BusinessInvitation",
      "BusinessPlan",
      "BusinessSeat",
      "Course",
      "Path"
    ],
    "Profile": [
      "PrivateUser",
      "User",
      "UserNotFound"
    ],
    "_Entity": [
      "Achievement",
      "BusinessCohort",
      "BusinessContentAssignment",
      "BusinessInvitation",
      "BusinessOrganization",
      "BusinessPlan",
      "BusinessSeat",
      "CatalogCategory",
      "CatalogCategoryGroup",
      "Certificate",
      "ContentItem",
      "Course",
      "DiscourseCohort",
      "DiscourseTopic",
      "ExternalCourse",
      "Image",
      "JobAnalysis",
      "JobResume",
      "Journey",
      "PrivateUser",
      "Resource",
      "User",
      "UserOrganizationMembership"
    ]
  }
};
      export default result;
    