import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
  RequestHandler,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { logError } from '@mono/data/logging';
import { isOnServer } from '@mono/util/env';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import requestIp from 'request-ip';

import {
  ApolloClientService,
  ApolloServiceConfig,
  CreateApolloContext,
  CreateApolloOptions,
  InitializeApollo,
  InitializeApolloOptions,
} from './typings/types';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const showContentfulPreviewQueryParam = 'preview';

class MiddlewareLink extends ApolloLink {
  constructor(
    request?: RequestHandler,
    private options?: { variables?: Record<string, string | boolean | number> }
  ) {
    super(request);
  }
  override request(operation: Operation, forward: NextLink) {
    operation.variables = {
      ...operation.variables,
      ...this.options?.variables,
    };
    return forward?.(operation);
  }
}

let apolloClientSingleton: ApolloClient<NormalizedCacheObject> | undefined;

/**
 * Welcome to the Pseudo Apollo Gateway in Frontend Code!
 * The goal of all this configuration is to provide callers of the Apollo Client a
 * consistent API for when we eventually move more GQL services behind a single
 * backend-driven gateway.
 * In the meantime, all of the configuration to add a new GQL service is up here.
 *
 * The default GQL service is assumed to be the monolith. But if you want a non-monolith
 * service, you'll want to pass that information via `context` as follows:
 *
 * const { data } = await client.query<QueryT, VariablesT>({
 *   query: queryT,
 *   variables: { ... },
 *   context: { service: ApolloClientService.GraphqlGateway } // or whatever service you want
 * })
 */

const createApolloClient = (
  { req, preview, res }: CreateApolloContext,
  options: CreateApolloOptions,
  apolloServiceConfig: Partial<ApolloServiceConfig>
) => {
  const { jwt } = options;
  const curriedOnError = (uri?: string) =>
    onError(({ operation, graphQLErrors, networkError }) => {
      graphQLErrors?.forEach((error) => {
        const { message, locations, path } = error;
        logError(error, {
          message: `[GraphQL error]: Message: ${message},\nLocation: ${
            JSON.stringify(locations) ?? '[undefined]'
          },\nPath: ${JSON.stringify(path) ?? '[undefined]'}`,
        });
      });

      if (networkError) {
        logError(networkError, {
          message: `[Network error]: ${networkError.toString()}. Backend is unreachable. Is it running? URI: ${uri}. Operation: ${JSON.stringify(
            operation
          )}`,
        });
      }
    });

  const getHttpLink = (service: ApolloClientService) => {
    const httpLink = HttpLink.from([
      curriedOnError(apolloServiceConfig[service]?.uri),
      // TODO: remove this after EGG-1744
      createUploadLink({
        credentials: 'include',
        uri: apolloServiceConfig[service]?.uri,
        fetch: (url: RequestInfo, init: RequestInit) =>
          fetch(url, {
            ...init,
            headers: {
              ...apolloServiceConfig[service]?.headers,
              ...init.headers,
              ...(req && {
                Cookie:
                  (res?.getHeader('cookie') as string) || req.headers.cookie,
                CODECADEMY_CLIENT_IP: requestIp.getClientIp(req) ?? undefined,
              }),
              ...(service === ApolloClientService.GraphqlGateway &&
                jwt && {
                  Authorization: `Bearer ${jwt}`,
                }),
            },
          }),
      }),
    ]);
    if (
      process.env.NEXT_PUBLIC_ENV !== 'production' &&
      [
        ApolloClientService.Contentful,
        ApolloClientService.ContentfulLandingPage,
      ].includes(service) &&
      preview
    ) {
      return ApolloLink.from([
        new MiddlewareLink(undefined, {
          variables: { [showContentfulPreviewQueryParam]: true },
        }),
        httpLink,
      ]);
    }
    return httpLink;
  };

  // At request-time, Apollo needs to know which endpoint (defined within `HttpLink`)
  // it should send the caller's request. By checking `context.service` on a given operation
  // we can route effectively. Rather than force deep nesting of if statements, we use recursion
  // and iteration across all of our configs to leverage nesting ternary-like API in
  // `HttpLink.split`.
  const getRecursiveLink = (services: ApolloClientService[]): ApolloLink => {
    const service = services.pop();
    if (!service) {
      return getHttpLink(ApolloClientService.Monolith); // base case
    }

    return HttpLink.split(
      (operation) => {
        const context = operation.getContext();
        // eslint-disable-next-line dot-notation
        return context['service'] === service;
      },
      getHttpLink(service),
      getRecursiveLink(services)
    );
  };

  // Apollo itself needs one of the objects emitted from each of the generated code to know what types
  // are available on its queries. Rather than adding each one manually, we iterate over all configs.
  const possibleTypes = Object.values(ApolloClientService).reduce(
    (possibleTypes, service) => ({
      ...possibleTypes,
      ...apolloServiceConfig[service]?.generatedTypes.possibleTypes,
    }),
    {}
  );

  return new ApolloClient({
    link: getRecursiveLink(
      // We filter monolith out of our recursive construction of links, since it's our base case
      Object.values(ApolloClientService).filter(
        (service) => service !== ApolloClientService.Monolith
      )
    ),
    ssrMode: isOnServer(),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        JobActionProgress: {
          keyFields: ['jobActionId'],
        },
      },
    }),
    name: process.env.NEXT_PUBLIC_APP_NAME,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
  });
};

export const initializeApollo: InitializeApollo = ({
  initialState,
  context,
  jwt,
  apolloServiceConfig = {} as Partial<ApolloServiceConfig>,
}: InitializeApolloOptions = {}) => {
  const apolloClient =
    apolloClientSingleton ??
    createApolloClient(context ?? {}, { jwt }, apolloServiceConfig);

  if (initialState) {
    // Get existing cache, loaded during any client side data fetching
    const existingCache = apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // Define the array combination logic to add any unique elements
      arrayMerge: <T>(destinationArray: T[], sourceArray: T[]): T[] => [
        ...sourceArray,
        ...destinationArray.filter(
          (d) => !sourceArray.some((s) => isEqual(d, s))
        ),
      ],
    });

    apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client (don't overwrite singleton)
  if (isOnServer()) return apolloClient;

  // For CSR only maintain a single Apollo Client
  if (!apolloClientSingleton) apolloClientSingleton = apolloClient;

  return apolloClient;
};

export type PagePropsWithApollo = {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
};

/**
 * This is used to extract the server-side Apollo cache, and inject it into
 * the props for a page, so we can initialize the Apollo client client-side
 * with this cache as an initial state.
 *
 * This allows us fetch for data server-side ahead of time, and
 * then later query for it client-side with the "cache-only" fetch policy
 * and receive the data synchronously. (see `useFeatureFlag` for an example)
 *
 * Note: Be careful to ensure your GraphQL queries include `id` fields for
 *       your various types, so Apollo cache can normalize them, otherwise
 *       your queries may clobber each other in the cache.
 *       (See https://www.apollographql.com/docs/react/caching/overview/#data-normalization)
 */
export const exportApolloCacheAsProps = (
  client: ApolloClient<NormalizedCacheObject>
) => ({
  [APOLLO_STATE_PROP_NAME]: client.cache?.extract() || {},
});

export const throwOnQueryError = <Query>(
  queryResult: ApolloQueryResult<Query>,
  errorMessage?: string
) => {
  const { error } = queryResult;
  if (error)
    throw new Error(
      errorMessage ? errorMessage + ': ' + error.message : error.message
    );
};

export const useApollo = (
  pageProps: PagePropsWithApollo,
  apolloServiceConfig: Partial<ApolloServiceConfig>
) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo({ initialState: state, apolloServiceConfig }),
    [state, apolloServiceConfig]
  );
  return store;
};
export { ApolloClientService };
export type { InitializeApolloOptions };
