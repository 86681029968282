import { initializeApollo } from '@mono/apollo';
import { FeatureFlagsAndExperimentsQuery } from '@mono/data/codegen/monolith';
import { FeatureFlag } from '@mono/data/feature-flags';
import { Experiments } from '@mono/data/optimizely';
import { useMemo } from 'react';

import { featureFlagsAndExperimentsQuery } from '~/components/PortalPage/queries';
import { useFeatureFlagsContext } from '~/libs/FeatureFlags/FeatureFlagsContext';

import { getApolloServiceConfig } from '../getApolloServiceConfig';
import { GetServerSidePropsContextWithAdditions } from '../nextJs/types';

export const useFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  const { featureFlags } = useFeatureFlagsContext();

  return useMemo(() => {
    return featureFlags.includes(featureFlag);
  }, [featureFlag, featureFlags]);
};

export const FEATURE_FLAGS_PROP_NAME = '__FEATURE_FLAGS__';

export type PagePropsWithFeatureFlags = {
  [FEATURE_FLAGS_PROP_NAME]?: FeatureFlag[];
};

export const exportFeatureFlagsAsProps = (featureFlags?: FeatureFlag[]) => ({
  [FEATURE_FLAGS_PROP_NAME]: featureFlags,
});

export const useFeatureFlagsFromServer = (
  pageProps: PagePropsWithFeatureFlags
): FeatureFlag[] => {
  return useMemo(() => pageProps[FEATURE_FLAGS_PROP_NAME] ?? [], [pageProps]);
};

export type FeatureFlagFetchParams = {
  context?: GetServerSidePropsContextWithAdditions;
  jwt?: string;
};

/**
 * This is temporarily fetching the feature flags with a separate instance of the Apollo client
 * while we investigate potential apollo cache clobbering issues.
 * See: https://codecademy.atlassian.net/browse/EGG-1898
 */
export const fetchUser = async ({
  context,
}: FeatureFlagFetchParams): Promise<{
  user: Partial<{
    id: string;
    anonymous: boolean;
    featureFlags: FeatureFlag[];
    experiments: Experiments;
    username: string;
  }>;
}> => {
  // Short-circuit if the data has already been added to the context.
  if (context?.user) {
    return {
      user: {
        id: context.user.id,
        anonymous: context.user.anonymous,
        featureFlags: context.user.featureFlags,
        experiments: context.user.experiments,
        username: context.user.username,
      },
    };
  }

  const client = initializeApollo({
    context,
    apolloServiceConfig: getApolloServiceConfig(),
  });

  try {
    const queryResults = await client.query<FeatureFlagsAndExperimentsQuery>({
      query: featureFlagsAndExperimentsQuery,
    });

    if (queryResults.error)
      throw new Error(
        `Error during feature flags and experiments query: ${queryResults.error.message}`
      );

    return {
      user: {
        id: queryResults.data.me.id,
        anonymous: queryResults.data.me.anonymous,
        featureFlags: queryResults.data.me.featureFlags as FeatureFlag[],
        experiments: queryResults.data.me.experiments as Experiments,
        username: queryResults.data.me.username,
      },
    };
  } catch (err) {
    throw new Error(
      `Could not complete user feature flags, experiments and anonymous query: ${err}`
    );
  }
};

// export type FeatureFlagFetchUserParams = {
//   context?: GetServerSidePropsContextWithAdditions;
// };

// This makes the user fields (featureFlags, experiments, etc.) available inside `getServerSideProps`
// without have to make an extra fetch (since the data is already fetched in `getServerSidePropsWrapper`).
export const fetchAndAddUserToContext = async ({
  context,
  jwt,
}: FeatureFlagFetchParams) => {
  if (!context) return;

  const {
    user: { id, featureFlags, experiments, anonymous, username },
  } = await fetchUser({
    context,
    jwt,
  });

  context.user = {
    id,
    anonymous,
    featureFlags,
    experiments,
    username,
  };
};
