import {
  Badge,
  Box,
  ContentContainer,
  FlexBox,
  FocusTrap,
  IconButton,
  Menu,
  MenuItem,
  Shimmer,
  StrokeButton,
  Text,
  TextButton,
} from '@codecademy/gamut';
import {
  MiniDeleteIcon,
  SearchIcon,
  SupportIcon,
} from '@codecademy/gamut-icons';
import { css, states, theme } from '@codecademy/gamut-styles';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import {
  CrossDeviceItemId,
  SiteSearchUpdgradeContext,
} from '../../GlobalHeader';
import { popularSearchTerms, searchPlaceholder } from './consts';
import { PopularContent, PopularSearches } from './DefaultResults';
import { QuizAndHelpCenterLinks } from './QuizAndHelpCenterLinks';
import {
  AutocompleteSuggestion,
  SearchAsYouTypeResults,
  SearchWorker,
} from './SearchWorker';

export type SearchPaneProps = {
  onSearch: (query: string, fromPrevSearch?: string) => void;
  onTrackingClick: (
    target: string,
    extraTrackingProps?: Record<string, string>
  ) => void;
  onSearchAsYouType?: (
    query: string,
    searchId: string,
    resultsCount: number,
    queryLoadTime: number
  ) => void;
  setOpenCrossDeviceItemId: (value: React.SetStateAction<string>) => void;
};

const Form = Box.withComponent('form');
const Input = Box.withComponent('input');

const QueryContainer = styled(ContentContainer)<{
  mobilePaddingUpdate?: boolean;
}>(
  css({
    display: 'flex',
    pb: 0,
    pt: 16,
    width: '100%',
  }),
  states({
    mobilePaddingUpdate: {
      pb: { _: 'unset', md: 24 },
      px: { _: 24 },
    },
  })
);

const SuggestionContainer = styled(ContentContainer)<{
  mobilePaddingUpdate?: boolean;
}>(
  css({
    pb: 24,
    pt: 16,
  }),
  states({
    mobilePaddingUpdate: {
      pb: { _: 'unset', md: 24 },
      px: { _: 24 },
    },
  })
);

const StyledInput = styled(Input)(
  css({
    outline: `none`,
    '&::placeholder': {
      textColor: theme.colors['text-secondary'] as never,
    },
  })
);

const InlineResultLi = styled(MenuItem)(
  css({
    pl: 0,
    py: 8,
    fontSize: 16,
    '&:focus-visible:after': {
      left: -4,
    },
  })
);

const InlineLoaderLi = styled.li(
  css({
    listStyleType: 'none',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  })
);

let SEARCH_WORKER: SearchWorker;

export const SemiboldSearchIcon = styled(SearchIcon)`
  overflow: visible !important;
  circle,
  path {
    stroke-width: 2px;
  }
  rect {
    transform: translate(-2px, -2px);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
`;

const EllipsisBox = styled(Box)`
  text-overflow: ellipsis;
`;

const HighlightedText = ({
  suggestion: { key, title, segments },
}: {
  suggestion: AutocompleteSuggestion;
}) => (
  <FlexBox>
    <EllipsisBox
      maxWidth="calc(100vw - 128px)"
      whiteSpace="pre"
      aria-hidden
      overflow="hidden"
    >
      {segments.map((segment, i) => (
        <Text
          key={`${key}:${i.toString()}`}
          lineHeight="title"
          {...(segment.highlight ? { bg: 'yellow', fontWeight: 'bold' } : {})}
        >
          {segment.value}
        </Text>
      ))}
    </EllipsisBox>
    <Text screenreader>{title}</Text>
  </FlexBox>
);

type SearchAsYouTypeLoader = {
  shimmerRows: {
    key: string;
    shimmers: {
      key: string;
      width: number;
    }[];
  }[];
};

let loaderKey = 0;
function rndLoader() {
  // eslint-disable-next-line no-plusplus
  const key = loaderKey++;
  const loader: SearchAsYouTypeLoader = { shimmerRows: [] };
  for (let i = 0; i < 5; i++) {
    const row = {
      key: `${key}:${i}`,
      shimmers: [] as { key: string; width: number }[],
    };
    const words = Math.ceil(Math.random() * 3);
    for (let j = 0; j < words; j++) {
      const width = 12 + Math.round(Math.random() * 96);
      row.shimmers.push({
        key: `${key}:${j}`,
        width,
      });
    }
    loader.shimmerRows.push(row);
  }
  return loader;
}

export const SearchPane: React.FC<SearchPaneProps> = ({
  onSearch,
  onTrackingClick,
  onSearchAsYouType,
  setOpenCrossDeviceItemId,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState<
    AutocompleteSuggestion[]
  >([]);
  const [searchAsYouTypeLoader, setSearchAsYouTypeLoader] =
    useState<SearchAsYouTypeLoader>(rndLoader());
  const [searchAsYouTypeResults, setSearchAsYouTypeResults] =
    useState<SearchAsYouTypeResults | null>(null);

  const handleCloseDropdown = () =>
    setOpenCrossDeviceItemId(CrossDeviceItemId.UNSET);

  const onMouseDownOutside = ({ target }: MouseEvent) => {
    const handleOutsideClick = () => {
      if (
        !document
          .querySelector('[data-testid="header-search-dropdown"]')
          ?.contains(target as HTMLElement)
      ) {
        setTimeout(() => handleCloseDropdown(), 100);
      }

      target?.removeEventListener('mouseup', handleOutsideClick);
    };

    target?.addEventListener('mouseup', handleOutsideClick);
  };

  const navigateToSearch = (searchTerm: string, fromPrevSearch?: string) => {
    onSearch(searchTerm, fromPrevSearch);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setValue(evt.target.value);
  };

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    navigateToSearch(value, searchAsYouTypeResults?.searchId);
  };

  const clearInput = () => {
    setValue('');
    setAutoCompleteSuggestions([]);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const displaySiteSearchUpgrades = React.useContext(SiteSearchUpdgradeContext);

  useEffect(() => {
    if (displaySiteSearchUpgrades && !SEARCH_WORKER) {
      SEARCH_WORKER = new SearchWorker();
    }
  }, [displaySiteSearchUpgrades]);

  useEffect(() => {
    if (!displaySiteSearchUpgrades) {
      return;
    }
    if (!value.trim().length) {
      setAutoCompleteSuggestions([]);
      return;
    }
    SEARCH_WORKER.autocomplete(value, 5).then((suggestions) => {
      // verify value hasn't changed during await
      if (value === inputRef.current?.value) {
        setAutoCompleteSuggestions(suggestions);
      }
    });
  }, [displaySiteSearchUpgrades, value, setAutoCompleteSuggestions]);

  useEffect(() => {
    if (!displaySiteSearchUpgrades) {
      return;
    }
    setSearchAsYouTypeResults(null);
    if (!value.trim().length) {
      return;
    }
    const t = setTimeout(async () => {
      if (value !== inputRef.current?.value) {
        return;
      }
      const results = await SEARCH_WORKER.searchAsYouType(value, 5);
      if (value !== inputRef.current?.value) {
        return;
      }
      setSearchAsYouTypeResults(results);
      if (onSearchAsYouType) {
        const { query, searchId, resultsCount, queryLoadTime } = results;
        onSearchAsYouType(query, searchId, resultsCount, queryLoadTime);
      }
    }, 250);
    return () => {
      clearTimeout(t);
    };
  }, [
    displaySiteSearchUpgrades,
    value,
    setSearchAsYouTypeResults,
    onSearchAsYouType,
  ]);

  useEffect(() => {
    if (searchAsYouTypeResults === null) {
      setSearchAsYouTypeLoader(rndLoader());
    }
  }, [searchAsYouTypeResults, setSearchAsYouTypeLoader]);

  return (
    <>
      <Box
        aria-hidden
        bg="shadow-black-slight"
        height="100vh"
        onClick={() => handleCloseDropdown()}
        position="fixed"
        // We add 5rem here in case there's some sort of branded banner above search
        // The search area is much taller than 5rem so this is a safe amount of padding
        top={`calc(${theme.elements.headerHeight} + 5rem)`}
        width={1}
      />
      <FocusTrap
        onClickOutside={onMouseDownOutside}
        onEscapeKey={() => handleCloseDropdown()}
        allowPageInteraction
      >
        <Box
          bg="background"
          borderColorBottom="text"
          borderColorTop="shadow-solid"
          borderStyle="solid"
          borderWidth="2px 0 1px"
          data-testid="header-search-dropdown"
          position="absolute"
          width="100%"
          maxHeight={{ _: 'calc(100vh - 63px)', md: 'calc(100vh - 80px)' }}
          overflow="auto"
        >
          <Box border="none" width="auto">
            <QueryContainer mobilePaddingUpdate={displaySiteSearchUpgrades}>
              <FlexBox
                alignItems="baseline"
                borderColor="gray-600"
                borderStyleBottom="solid"
                borderWidthBottom="1px"
                width="100%"
              >
                <SearchIcon
                  height={displaySiteSearchUpgrades ? { _: 20, md: 24 } : 24}
                  width={displaySiteSearchUpgrades ? { _: 20, md: 24 } : 24}
                />
                <Form
                  action="/search"
                  id="search-form"
                  ml={8}
                  onSubmit={handleSubmit}
                  width="100%"
                >
                  <StyledInput
                    background="none"
                    border="none"
                    color="text"
                    fontSize={
                      displaySiteSearchUpgrades ? { _: 20, md: 26 } : 34
                    }
                    fontWeight="bold"
                    id="header-search-bar"
                    name="query"
                    onChange={handleChange}
                    onKeyDown={(event) =>
                      event.key !== 'Escape' && event.stopPropagation()
                    }
                    placeholder={searchPlaceholder}
                    ref={inputRef}
                    type="search"
                    value={value}
                    width="100%"
                    autoComplete="off"
                  />
                </Form>
                {displaySiteSearchUpgrades && (
                  <IconButton
                    icon={MiniDeleteIcon}
                    aria-label="Clear search"
                    tip="Clear search"
                    tipProps={{
                      alignment: 'bottom-center',
                      placement: 'floating',
                      narrow: true,
                      hideAriaToolTip: true,
                      zIndex: 2,
                    }}
                    onClick={clearInput}
                    size="small"
                  />
                )}
              </FlexBox>
            </QueryContainer>
          </Box>
          <SuggestionContainer mobilePaddingUpdate={displaySiteSearchUpgrades}>
            {displaySiteSearchUpgrades && (
              <>
                {value.trim().length > 0 ? (
                  <>
                    <FlexBox
                      flexDirection="column"
                      as="ul"
                      listStyle="none"
                      p={0}
                    >
                      {autoCompleteSuggestions.map((s) => (
                        <InlineResultLi
                          key={s.key}
                          tabIndex={0}
                          onKeyDown={(evt) => {
                            if (evt.key === 'Enter') {
                              onTrackingClick('autocomplete_result', {
                                search_id:
                                  searchAsYouTypeResults?.searchId ?? '',
                                misc: JSON.stringify({ value: s.title }),
                              });
                              navigateToSearch(
                                s.title,
                                searchAsYouTypeResults?.searchId
                              );
                            }
                            handleCloseDropdown();
                          }}
                          onClick={() => {
                            onTrackingClick('autocomplete_result', {
                              search_id: searchAsYouTypeResults?.searchId ?? '',
                              misc: JSON.stringify({ value: s.title }),
                            });
                            navigateToSearch(
                              s.title,
                              searchAsYouTypeResults?.searchId
                            );
                            handleCloseDropdown();
                          }}
                        >
                          <SemiboldSearchIcon
                            mb={2 as 0}
                            size={14}
                            strokeWidth={8}
                            aria-hidden
                            mr={12}
                          />
                          <HighlightedText suggestion={s} />
                        </InlineResultLi>
                      ))}
                    </FlexBox>
                    {(searchAsYouTypeResults === null ||
                      searchAsYouTypeResults.top.length > 0) && (
                      <>
                        <Text as="h2" fontSize={20} mb={16} mt={24}>
                          Top results
                        </Text>
                        <FlexBox
                          flexDirection="column"
                          as="ul"
                          listStyle="none"
                          p={0}
                        >
                          {searchAsYouTypeResults === null
                            ? searchAsYouTypeLoader.shimmerRows.map((r) => (
                                <InlineLoaderLi key={r.key}>
                                  {r.shimmers.map((word) => (
                                    <Shimmer
                                      height={30}
                                      py={8 as 0}
                                      key={word.key}
                                      width={word.width}
                                    />
                                  ))}
                                  <Box
                                    fontFamily="accent"
                                    textColor="text-secondary"
                                    fontSize={{ _: 10 as 16 }}
                                    borderColor="text-secondary"
                                    border={1}
                                    borderRadius="16px"
                                    px={16}
                                    opacity={0.1}
                                  >
                                    &nbsp;
                                  </Box>
                                </InlineLoaderLi>
                              ))
                            : searchAsYouTypeResults.top.map((s, i) => (
                                <InlineResultLi
                                  key={`${
                                    searchAsYouTypeResults.query
                                  }:${i.toString()}`}
                                  tabIndex={0}
                                  role="link"
                                  onKeyDown={(evt) => {
                                    if (evt.key === 'Enter') {
                                      onTrackingClick(
                                        'search_as_you_type_result',
                                        {
                                          search_id:
                                            searchAsYouTypeResults.searchId,
                                          slug: s.slug,
                                          ...(s.contentId
                                            ? { content_id: s.contentId }
                                            : {}),
                                        }
                                      );
                                      handleCloseDropdown();
                                      window.location.assign(s.urlPath);
                                    }
                                  }}
                                  onClick={() => {
                                    onTrackingClick(
                                      'search_as_you_type_result',
                                      {
                                        search_id:
                                          searchAsYouTypeResults.searchId,
                                        slug: s.slug,
                                        ...(s.contentId
                                          ? { content_id: s.contentId }
                                          : {}),
                                      }
                                    );
                                    handleCloseDropdown();
                                    window.location.assign(s.urlPath);
                                  }}
                                >
                                  <HighlightedText suggestion={s} />
                                  <Badge size="sm" variant="tertiary" ml={12}>
                                    {s.type}
                                  </Badge>
                                </InlineResultLi>
                              ))}
                        </FlexBox>
                      </>
                    )}
                    {searchAsYouTypeResults?.top.length === 0 && (
                      <>
                        <Box fontSize={{ _: 20, sm: 26 }} mt={0} mb={48}>
                          {`We couldn't find a match for `}
                          <Text fontWeight="bold">{`"${value.trim()}."`}</Text>
                          {
                            ' Try another keyword, or see what our members are learning.'
                          }
                        </Box>
                        <Menu border="none" variant="select">
                          <PopularContent onTrackingClick={onTrackingClick} />
                        </Menu>
                      </>
                    )}
                  </>
                ) : (
                  <Menu border="none" variant="select">
                    <PopularSearches
                      navigateToSearch={navigateToSearch}
                      onTrackingClick={onTrackingClick}
                    />
                    <PopularContent onTrackingClick={onTrackingClick} />
                  </Menu>
                )}
              </>
            )}
            {!!searchAsYouTypeResults?.top.length && (
              <StrokeButton
                my={16}
                onClick={() => {
                  onTrackingClick('view_all_results', {
                    search_id: searchAsYouTypeResults.searchId,
                  });
                  navigateToSearch(value, searchAsYouTypeResults?.searchId);
                  handleCloseDropdown();
                }}
              >
                View all results
              </StrokeButton>
            )}

            {displaySiteSearchUpgrades ? (
              <QuizAndHelpCenterLinks
                onTrackingClick={onTrackingClick}
                handleCloseDropdown={handleCloseDropdown}
              />
            ) : (
              <>
                <Text as="h2" fontSize={22} mb={16} mt={24}>
                  Popular searches
                </Text>
                <FlexBox
                  as="ul"
                  listStyle="none"
                  p={0}
                  justifyContent="space-between"
                >
                  <li>
                    {popularSearchTerms.map((searchTerm, i) => (
                      <TextButton
                        role="link"
                        mr={16}
                        key={searchTerm}
                        onClick={() => {
                          navigateToSearch(
                            searchTerm,
                            searchAsYouTypeResults?.searchId
                          );
                          onTrackingClick(`popular_search_term`, {
                            misc: JSON.stringify({
                              value: searchTerm,
                              index: i,
                            }),
                          });
                        }}
                      >
                        {searchTerm}
                      </TextButton>
                    ))}
                  </li>
                  <TextButton
                    href="/help"
                    onClick={() => onTrackingClick('help_center')}
                  >
                    <SupportIcon mr={8} size={20} /> Help Center
                  </TextButton>
                </FlexBox>
              </>
            )}
          </SuggestionContainer>
        </Box>
      </FocusTrap>
    </>
  );
};
