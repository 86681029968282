import {
  AnonHeader,
  EnterpriseHeader,
  FreeHeader,
  LoadingHeader,
  ProHeader,
} from '@mono/brand';
import { AnonymousUser, LoggedInUser, userIsLoggedIn } from '@mono/data/user';

import { useEnterpriseUser } from '~/libs/useEnterpriseUser';

import { useUserNotifications } from './useUserNotifications';

export type NormalizedUserProps =
  | Pick<LoadingHeader, 'type'>
  | Pick<AnonHeader, 'type' | 'user'>
  | Pick<EnterpriseHeader, 'type' | 'user'>
  | Pick<FreeHeader | ProHeader, 'notifications' | 'type' | 'user'>;

export const useNormalizedUserProps = (
  user: AnonymousUser | LoggedInUser | undefined
): NormalizedUserProps => {
  const notifications = useUserNotifications(user);
  const enterpriseUser = useEnterpriseUser(user);

  if (!user) {
    return {
      type: 'loading',
    } as const;
  }

  if (userIsLoggedIn(user) && enterpriseUser) {
    return {
      type: 'enterprise',
      user: {
        avatar: user.profile_image_url,
        displayName: user.name || user.username || user.email,
        isAdmin: user.roles.admin,
        isCustomerSupport: user.roles.customer_service,
        isPaused: user.isPaused,
        isAccountManager: user.isAccountManager,
        hideCareerPaths: !user.canAccessCareerPaths,
        isBusinessAdmin: user.isBusinessAdmin,
        isBusinessSsoUser: user.isBusinessSsoUser,
        hideBusinessAccount: user.hideBusinessAccount,
        enterpriseUrl: user.externalBusinessSite,
      },
    } as const;
  }

  if (userIsLoggedIn(user) && notifications) {
    return {
      notifications,
      type: user.is_pro ? 'pro' : 'free',
      user: {
        avatar: user.profile_image_url,
        displayName: user.name || user.username || user.email,
        isAdmin: user.roles.admin,
        isCustomerSupport: user.roles.customer_service,
        isPaused: user.isPaused,
        isPlusUser: user.isPlusUser,
        proCheckoutUrl: user.upgradeUrl,
        showMoneybackCTA: user.showMoneybackCTA,
        showProUpgrade: !user.showTrialCTA,
        isAccountManager: user.isAccountManager,
        hideCareerPaths: !user.canAccessCareerPaths,
        isBusinessAdmin: user.isBusinessAdmin,
        isBusinessSsoUser: user.isBusinessSsoUser,
        hideBusinessAccount: user.hideBusinessAccount,
      },
    } as const;
  }

  return {
    type: 'anon',
    user: {
      avatar: '',
      displayName: '',
      hideCareerPaths: !user.canAccessCareerPaths,
    },
  } as const;
};
