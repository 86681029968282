import { GlobalPage, GlobalPageBackgroundColor } from '@mono/brand';
import { usePastDueModalUserQuery } from '@mono/data/codegen/monolith';
import {
  LoggedInUser,
  LoggedInUserResponseData,
  userIsLoggedIn,
  useUser,
} from '@mono/data/user';
import { UserVisitData } from '@mono/tracking';
import { isChromeOSPWA } from '@mono/util/device';
import React from 'react';

import { GlobalPortalMeta, usePortalMeta } from '~/libs/usePortalMeta';
import { useUserVisit } from '~/libs/useUserVisit';

import { useBrandedBanner } from './BrandedBanner/useBrandedBanner';
import { CheckoutSuccessModal } from './CheckoutSuccessModal';
import { PastDueModal } from './PastDueModal';
import { useFooterProps } from './useFooterProps';
import { useHeaderProps } from './useHeaderProps';

export interface PortalPageProps extends React.PropsWithChildren {
  backgroundColor?: GlobalPageBackgroundColor;
  meta: GlobalPortalMeta;
  skipToContentId?: string;
  tracking: UserVisitData;
  hideFooter?: boolean;
  simpleHeader?: boolean;
}

export const PortalPage: React.FC<PortalPageProps> = ({
  backgroundColor,
  children,
  meta,
  skipToContentId,
  tracking,
  hideFooter,
  simpleHeader = false,
}) => {
  const head = usePortalMeta(meta);
  const user = useUser();

  const pwaMode = isChromeOSPWA();

  const footer = useFooterProps(pwaMode, !!hideFooter);
  const header = useHeaderProps(pwaMode, user, simpleHeader);
  const banner = useBrandedBanner();

  React.useEffect(() => {
    window.dataLayer ||= [];
    const loggedInUser = user as LoggedInUser;
    if (loggedInUser?.email) {
      window.dataLayer.push({ codecademy_email: loggedInUser.email });
    }
  }, [user]);

  useUserVisit(tracking);

  return (
    <GlobalPage
      backgroundColor={backgroundColor}
      banner={banner}
      footer={footer}
      header={header}
      skipToContentId={skipToContentId}
    >
      {head}
      {children}
      {user && userIsLoggedIn(user) && (
        <LoggedInModals user={user} pageName={tracking.page_name} />
      )}
    </GlobalPage>
  );
};

interface LoggedInModalsProps {
  user: LoggedInUser;
  pageName: string;
}

const LoggedInModals: React.FC<LoggedInModalsProps> = ({ user, pageName }) => {
  const { data: pastDueUserData } = usePastDueModalUserQuery();
  return (
    <>
      {!!pastDueUserData?.me?.pastDueErrCode && (
        <PastDueModal
          user={user as LoggedInUserResponseData}
          isPaypal={pastDueUserData?.me?.latestPaymentMethod === 'paypal'}
          isPastDue={pastDueUserData?.me?.pastDue}
          pastDueErrCode={pastDueUserData?.me?.pastDueErrCode}
          currentRenewalDate={pastDueUserData?.me?.currentRenewalDate}
          paymentLink={pastDueUserData?.me?.paymentLink}
          cancellationLink={pastDueUserData?.me?.cancellationLink}
          provider={pastDueUserData?.me?.provider}
        />
      )}
      <CheckoutSuccessModal user={user} pageName={pageName} />
    </>
  );
};
