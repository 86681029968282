import { Box } from '@codecademy/gamut';
import { useTheme } from '@emotion/react';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { AppHeader, AppHeaderMobile } from '..';
import {
  AppHeaderItem,
  isAppHeaderItemWithHref,
} from '../AppHeader/AppHeaderElements/types';
import {
  FormattedAppHeaderItems,
  FormattedMobileAppHeaderItems,
} from '../AppHeader/types';
import {
  anonDefaultHeaderItems,
  anonDefaultMobileHeaderItems,
  anonLandingHeaderItems,
  anonLandingMobileHeaderItems,
  anonLoginHeaderItems,
  anonLoginMobileHeaderItems,
  anonSignupHeaderItems,
  anonSignupMobileHeaderItems,
  enterpriseHeaderItems,
  enterpriseMobileHeaderItems,
  freeHeaderItems,
  freeMobileHeaderItems,
  loadingHeaderItems,
  loadingMobileHeaderItems,
  proHeaderItems,
  proMobileHeaderItems,
  simpleHeaderItems,
  simpleMobileHeaderItems,
} from './GlobalHeaderVariants';
import {
  AnonHeader,
  CrossDeviceItemId,
  EnterpriseHeader,
  FreeHeader,
  LoadingHeader,
  ProHeader,
  SimpleHeader,
} from './types';

export * from './types';

export type GlobalHeaderProps =
  | AnonHeader
  | FreeHeader
  | ProHeader
  | EnterpriseHeader
  | LoadingHeader
  | SimpleHeader;

// Overloading getAppHeaderItems function to return different types based on mobile parameter
function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: false
): FormattedAppHeaderItems;

function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: true
): FormattedMobileAppHeaderItems;

function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: Boolean
): FormattedAppHeaderItems | FormattedMobileAppHeaderItems {
  const { hidePricing } = props;
  switch (props.type) {
    case 'anon':
      switch (props.variant) {
        case 'landing':
          return mobile
            ? anonLandingMobileHeaderItems(
                hidePricing,
                props.user,
                props.siteSearchUpgrade
              )
            : anonLandingHeaderItems(hidePricing, props.user);
        case 'login':
          return mobile
            ? anonLoginMobileHeaderItems(
                hidePricing,
                props.user,
                props.siteSearchUpgrade
              )
            : anonLoginHeaderItems(hidePricing, props.user);
        case 'signup':
          return mobile
            ? anonSignupMobileHeaderItems(
                hidePricing,
                props.user,
                props.siteSearchUpgrade
              )
            : anonSignupHeaderItems(hidePricing, props.user);
        default:
          return mobile
            ? anonDefaultMobileHeaderItems(
                hidePricing,
                props.user,
                props.siteSearchUpgrade
              )
            : anonDefaultHeaderItems(hidePricing, props.user);
      }
    case 'enterprise':
      return mobile
        ? enterpriseMobileHeaderItems(props.user, props)
        : enterpriseHeaderItems(props.user, props);
    case 'free':
      const showMoneyback =
        props.isInMoneyBackGuaranteeVariant && props.user.showMoneybackCTA;
      return mobile
        ? freeMobileHeaderItems(props.user, hidePricing, showMoneyback)
        : freeHeaderItems(props.user, hidePricing, showMoneyback);
    case 'pro':
      return mobile
        ? proMobileHeaderItems(props.user)
        : proHeaderItems(props.user);
    case 'simple':
      return mobile ? simpleMobileHeaderItems : simpleHeaderItems;
    case 'loading':
      return mobile ? loadingMobileHeaderItems : loadingHeaderItems;
  }
}

export const SiteSearchUpdgradeContext = React.createContext<boolean>(false);

export const GlobalHeader: React.FC<GlobalHeaderProps> = (props) => {
  const { action, onLinkAction } = props;
  const theme = useTheme();

  const combinedAction = useCallback(
    (event: React.MouseEvent, item: AppHeaderItem) => {
      action(event, item);
      if (isAppHeaderItemWithHref(item)) onLinkAction?.(event, item);
    },
    [action, onLinkAction]
  );

  // manages global toggle state for items (only 1 open at a time)
  const [openCrossDeviceItemId, setOpenCrossDeviceItemId] = useState(
    CrossDeviceItemId.UNSET
  );

  const hideNotification =
    props.type === 'loading' ||
    props.type === 'enterprise' ||
    props.type === 'simple';

  return (
    <SiteSearchUpdgradeContext.Provider value={!!props.siteSearchUpgrade}>
      <Box
        as="header"
        position="sticky"
        top={0}
        zIndex={theme.elements.headerZ}
      >
        <AppHeader
          action={combinedAction}
          items={getAppHeaderItems(props, false)}
          search={props.search}
          {...(props.type === 'anon'
            ? {
                redirectParam: props.redirectParam,
              }
            : hideNotification
            ? {}
            : {
                notifications: props.notifications,
              })}
          hideRightButtonDefaults={hideNotification}
          isAnon={props.type === 'anon'}
          openCrossDeviceItemId={openCrossDeviceItemId}
          setOpenCrossDeviceItemId={setOpenCrossDeviceItemId}
        />
        <AppHeaderMobile
          action={combinedAction}
          items={getAppHeaderItems(props, true)}
          {...(props.type === 'anon' || hideNotification
            ? {}
            : {
                notifications: props.notifications,
              })}
          search={props.search}
          onSearch={props.search.onSearch}
          redirectParam={
            props.type === 'anon' ? props.redirectParam : undefined
          }
          isEnterprise={props.type === 'enterprise'}
          isAnon={props.type === 'anon'}
          isSimple={props.type === 'simple'}
          hideRightMenuButton={
            props.type === 'simple' || props.type === 'loading'
          }
          openCrossDeviceItemId={openCrossDeviceItemId}
          setOpenCrossDeviceItemId={setOpenCrossDeviceItemId}
        />
        {props.children}
      </Box>
    </SiteSearchUpdgradeContext.Provider>
  );
};
