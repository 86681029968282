import { IconButton } from '@codecademy/gamut';
import { SearchIcon } from '@codecademy/gamut-icons';

import { CrossDeviceItemId, CrossDeviceStateProps } from '../../GlobalHeader';
import { AnimatedHeaderZone } from '../shared';
import { SearchPane } from './SearchPane';
import { getPortalOrigin } from './SearchWorker/src';

export type AppHeaderSearch = {
  onEnable: () => void;
  onSearch: (query: string, fromPrevSearch?: string) => void;
  onTrackingClick: (
    target: string,
    extraTrackingProps?: Record<string, string>
  ) => void;
  onSearchAsYouType?: (
    query: string,
    searchId: string,
    resultsCount: number,
    queryLoadTime: number
  ) => void;
};

type AppHeaderSearchContentProps = AppHeaderSearch & CrossDeviceStateProps;

let autocompleteCacheWarmed = false;

function warmAutocompleteCache() {
  if (typeof window !== 'undefined' && !autocompleteCacheWarmed) {
    autocompleteCacheWarmed = true;
    const { search } = window.location;
    const origin = getPortalOrigin();
    const apiUrl = `${origin}/api/portal/search/autocomplete-preload/${search}`;
    const headers = { 'Warm-Only': 'true' };
    fetch(apiUrl, { headers });
  }
}

export const useHeaderSearch = ({
  onEnable,
  onSearch,
  onTrackingClick,
  onSearchAsYouType,
  openCrossDeviceItemId,
  setOpenCrossDeviceItemId,
}: AppHeaderSearchContentProps) => {
  const id = CrossDeviceItemId.SEARCH;

  const toggleSearch = () => {
    const toggle = openCrossDeviceItemId !== id;

    if (toggle) {
      onEnable();
    }

    setOpenCrossDeviceItemId(toggle ? id : CrossDeviceItemId.UNSET);
  };

  return [
    {
      id,
      type: 'render-element',
      renderElement: () => (
        <IconButton
          data-testid="header-search"
          onClick={toggleSearch}
          onMouseOver={warmAutocompleteCache}
          icon={SearchIcon}
          role="menuitem"
          variant="interface"
          tip={openCrossDeviceItemId === id ? 'Close search' : 'Open search'}
          tipProps={{
            alignment: 'bottom-center',
            placement: 'floating',
          }}
        />
      ),
    },
    <AnimatedHeaderZone
      visible={openCrossDeviceItemId === id}
      key="search-content"
    >
      <SearchPane
        onSearch={onSearch}
        onTrackingClick={onTrackingClick}
        onSearchAsYouType={onSearchAsYouType}
        setOpenCrossDeviceItemId={setOpenCrossDeviceItemId}
      />
    </AnimatedHeaderZone>,
  ] as const;
};
