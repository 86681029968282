import { Anchor, FlexBox, Text, TextButton } from '@codecademy/gamut';
import { MiniArrowRightIcon, SupportIcon } from '@codecademy/gamut-icons';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const BoldSupportIcon = styled(SupportIcon)`
  path {
    stroke-width: 2.5;
  }
  circle {
    stroke-width: 2.5;
  }
`;

export const QuizAndHelpCenterLinks: React.FC<{
  onTrackingClick: (target: string) => void;
  handleCloseDropdown: () => void;
}> = ({ onTrackingClick, handleCloseDropdown }) => {
  const isMobile = theme.breakpoints.xs || theme.breakpoints.sm;

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      borderTop={1}
      borderColor="navy-300"
      py={8}
    >
      <FlexBox flexDirection={{ _: 'column', md: 'row' }}>
        <Text fontSize={16} mr={10 as 0}>
          Not sure where to begin?
        </Text>
        <Anchor
          href="/explore/sorting-quiz"
          variant="standard"
          onClick={() => {
            onTrackingClick('onboarding_quiz');
            handleCloseDropdown();
          }}
        >
          <FlexBox alignItems="center" gap={10 as 0} overflow="visible">
            <Text fontSize={{ _: 14, md: 16 }}>Take our quiz</Text>
            <MiniArrowRightIcon size={12} />
          </FlexBox>
        </Anchor>
      </FlexBox>
      <TextButton
        href="/help"
        onClick={() => onTrackingClick('help_center')}
        size={isMobile ? 'small' : 'normal'}
      >
        <BoldSupportIcon mr={8} size={12} />
        Help Center
      </TextButton>
    </FlexBox>
  );
};
