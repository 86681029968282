import { CourseDifficulty, EnrollmentStatus } from '@mono/brand';

export type CourseData = {
  id: string;
  slug: string;
  urlPath: string;
  title: string;
  lessonCount: number;
  grantsCertificate: boolean;
  enrollmentStatus: EnrollmentStatus;
  pro: boolean;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  type: 'course';
  rank?: number | null;
  createdAt?: string | null;
  popularity?: number | null;
};

export enum PathGoalEnum {
  Career = 'career',
  Skill = 'skill',
}

export type PathData = {
  id: string;
  slug: string;
  urlPath: string;
  imageUrl: string;
  goal: PathGoalEnum;
  title: string;
  lessonCount: number;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  hasCareerJourney: boolean; // career paths only
  type: 'path';
  rank?: number | null;
  createdAt?: string | null;
  popularity?: number | null;
};

export type CurriculumCardData = CourseData | PathData;

export const isCourseData = (
  content: CurriculumCardData
): content is CourseData => content.type === 'course';

export const isPathData = (content: CurriculumCardData): content is PathData =>
  content.type === 'path';
