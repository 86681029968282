import { Anchor } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';

import { appHeaderMobileBreakpoint } from '../../shared';
import { AppHeaderClickHandler, AppHeaderLinkItem } from '../types';

export type AppHeaderLinkProps = React.ComponentProps<typeof Anchor> & {
  action: AppHeaderClickHandler;
  item: AppHeaderLinkItem;
  showIcon?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

const StyledAnchor = styled(Anchor)(
  css({
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
    lineHeight: 'base',
    minWidth: '0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  })
);

export const AppHeaderLink: React.FC<AppHeaderLinkProps> = ({
  action,
  item,
  showIcon = false,
  mx = { _: 0, [appHeaderMobileBreakpoint]: 24 },
  py = { _: 16, [appHeaderMobileBreakpoint]: 8 },
  onKeyDown,
  ...props
}) => {
  const Icon = item.icon;

  return (
    <StyledAnchor
      data-testid={item.dataTestId}
      href={item.href}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
        action(event, item)
      }
      onKeyDown={onKeyDown}
      role="menuitem"
      target={item.newTab ? 'blank' : ''}
      variant="interface"
      // makes sure this is level with our dropdowns, which have a 1px height differential
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mb={{ _: 0, [appHeaderMobileBreakpoint]: 1 as any }}
      mx={mx}
      py={py}
      {...props}
    >
      {showIcon && Icon && <Icon mr={16} size={24} aria-hidden />}
      {item.text}
      {item.badge}
    </StyledAnchor>
  );
};
